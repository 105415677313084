import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { ArrowUpTrayIcon, InformationCircleIcon, PlayIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import { CurrencyText, DateText, Input } from "../../../../../Utils/Template";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Translate from "../../../../../Utils/Translate";
import F from "../../../../../Utils/Functions";
import PackagesMenu from "../components/PackagesMenu";
import axios from "axios";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import { useParams } from "react-router-dom";
import Swal from'sweetalert2';

const movementStatuses = [
    { "label": "(empty)", "value": "" },
    { "label": "En Route to FL", "value": "new" },
    { "label": "Florida Warehouse", "value": "warehouse" },
    { "label": "In Flight", "value": "inflight" },
    { "label": "Clearing", "value": "customs" },
    { "label": "Kingston Warehouse", "value": "cleared" },
    { "label": "Ready", "value": "ready" },
    { "label": "Delivering", "value": "delivering" },
    { "label": "Delivered", "value": "delivered" },
    { "label": "Removed", "value": "removed" },
];
const paymentStatuses = [
    { "label": "(empty)", "value": "" },
    { "label": "Paid", "value": "paid" },
    { "label": "Unpaid", "value": "unpaid" },
];
const etaStatuses = [
    { "label": "(empty)", "value": "" },
    { "label": "On Time", "value": "onTime" },
    { "label": "Delayed", "value": "delayed" },
];
const invoiceStatuses = [
    { "label": "(empty)", "value": "" },
    { "label": "Received", "value": "received" },
    { "label": "Requested", "value": "requested" },
    { "label": "Required", "value": "required" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function Manage(props) {

    let { packageId, pageId } = useParams();

    const pageTitles = {
        details: 'Package Management',
        invoice: 'Invoice',
        billing: 'Billing',
        charges: 'Charges',
        status: 'Status'
    }

    useDocumentTitle(pageTitles[pageId || 'details']);

    const [packageInfo, setPackageInfo] = useState({});
    const [originalPackageInfo, setOriginalPackageInfo] = useState({});
    const [memberInfo, setMemberInfo] = useState({});
    const [customerId, setCustomerId] = useState('');
    const [page, setPage] = useState('details');
    const [fileName, setSelectedFileName] = useState('No file selected yet');
    const [hasFile, setHasFile] = useState(false);

    const tabs = [
        { name: 'Details', href: `/packages/${packageId}/manage/details`, current: pageId === 'details' || pageId === '' || !pageId },
        { name: 'Invoice', href: `/packages/${packageId}/manage/invoice`, current: pageId === 'invoice' },
        { name: 'Charges', href: `/packages/${packageId}/manage/charges`, current: pageId === 'charges' },
        { name: 'Billing', href: `/packages/${packageId}/manage/billing`, current: pageId === 'billing' },
        { name: 'Status', href: `/packages/${packageId}/manage/status`, current: pageId === 'status' },
    ]

    const handleChange = e => setPackageInfo({ ...packageInfo, [e.target.name]: e.target.value });

    useEffect(() => {
        fetchPackage();
        setPage(pageId);
    }, []);

    const fetchPackage = async () => {
        const response = await Http.request({ uri: `/packages/${packageId}/get`, body: {} });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
            if (!response.body.paymentStatus) response.body.paymentStatus = '';
            if (!response.body.etaStatus) response.body.etaStatus = '';
            if (!response.body.invoiceStatus) response.body.invoiceStatus = '';
            if (!response.body.movementStatus) response.body.movementStatus = '';
            setPackageInfo(response.body);
            setOriginalPackageInfo(response.body);
            if (response.body.customerId) {
                setCustomerId(response.body.customerId);
                fetchMember(response.body.customerId);
            }
        }
    };

    const fetchMember = async (id) => {
        const response = await Http.request({ uri: `/customers/${customerId || id}/get`, body: {} });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
            setMemberInfo(response.body);
        }
    };

    const savePackageInfo = async () => {
        let body = packageInfo;
        const response = await Http.request({ uri: `/packages/${packageId}/update`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const selectFile = async e => {
        if (e) e.preventDefault();
        let fileList = e.target.files;
        let files = [];
        let fileCount = 1;
        for (let key in fileList) {
            let file = fileList[key];
            if (file.name && file.size && fileCount === 1) {
                fileCount++;
                files.push(file);
            }
        }

        const formData = new FormData();
        formData.append('invoiceBinary', files[0]);

        setSelectedFileName(files[0].name);
        setHasFile(true);

        await axios.post(window.getServiceEndpoint() + `/packages/${packageId}/add-invoice`, formData, window.getAxiosConfig())
            .then(result => {
                if (typeof result.data !== 'undefined') {
                }
                if (result.data.notification) {
                    Notifier(result.data.notification);
                }
            }).catch(e => {
                if (e.data.notification) {
                    Notifier(e.data.notification);
                }
            });
    };

    const handleEnterKey = e => {
        if (e.key === 'Enter') {
            savePackageInfo();
        }
    }

    const [assignment, setAssignment] = useState({});
    const handleChangeAssign = e => setAssignment({ ...assignment, [e.target.name]: e.target.value });

    const submitBoxAssignment = async () => {
        let body = { ...assignment, packageId };
        const response = await Http.request({ uri: `/packages/assign`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
            fetchPackage();
        }
    };

    const [creditPosting, setCreditPosting] = useState({});
    const [debitPosting, setDebitPosting] = useState({});
    const handleChangeCreditPosting = e => setCreditPosting({ ...creditPosting, [e.target.name]: e.target.value });
    const handleChangeDebitPosting = e => setDebitPosting({ ...debitPosting, [e.target.name]: e.target.value });

    const submitCreditPosting = async () => {
        let body = creditPosting;
        const response = await Http.request({ uri: `/payments/${customerId}/post-credit`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const submitDebitPosting = async () => {
        let body = debitPosting;
        const response = await Http.request({ uri: `/payments/${customerId}/post-debit`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const updateCharges = async () => {
        let body = {};
        const response = await Http.request({ uri: `/payments/${packageId}/update-charges`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const updateAllCharges = async () => {
        let body = {};
        const response = await Http.request({ uri: `/payments/${customerId}/update-all-charges`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const [statusInfo, setStatusInfo] = useState({});

    const updateStatus = async field => {
        let body = { fieldName: field, fieldValue: packageInfo[field] };
        const response = await Http.request({ uri: `/packages/${packageId}/set-status`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        if (response.result === 'success') {
        }
    };

    const setInvoiceStatus = invoiceStatus => setPackageInfo({ ...packageInfo, invoiceStatus });
    const setMovementStatus = movementStatus => setPackageInfo({ ...packageInfo, movementStatus });
    const setPaymentStatus = paymentStatus => setPackageInfo({ ...packageInfo, paymentStatus });
    const setEtaStatus = etaStatus => setPackageInfo({ ...packageInfo, etaStatus });

    return (
        <>
            <div className="flex min-h-full">
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
                    <Header title={"Package Management"}></Header>
                    <br />
                    <Container>

                        <div className="grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">
                            <div className={"col-span-3"}>

                                <div className="sm:hidden">
                                    <label htmlFor="tabs" className="sr-only">
                                        <Translate text={"Select a tab"} />
                                    </label>
                                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                    <select
                                        id="tabs"
                                        name="tabs"
                                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                        defaultValue={tabs.find((tab) => tab.current).name}
                                    >
                                        {tabs.map((tab) => (
                                            <option key={tab.name}>{tab.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="hidden sm:block">
                                    <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                                        {tabs.map((tab, tabIdx) => (
                                            <a
                                                key={tab.name}
                                                href={tab.href}
                                                className={classNames(
                                                    tab.current ? 'text-gray-900 bg-blue-200' : 'text-gray-500 hover:text-gray-700',
                                                    tabIdx === 0 ? 'rounded-l-lg' : '',
                                                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                                                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                                                )}
                                                aria-current={tab.current ? 'page' : undefined}
                                            >
                                                <span>{tab.name}</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        tab.current ? 'bg-indigo-500' : 'bg-transparent',
                                                        'absolute inset-x-0 bottom-0 h-0.5'
                                                    )}
                                                />
                                            </a>
                                        ))}
                                    </nav>
                                </div>

                                <br />

                                {(page === 'details' || page === '' || !page) &&
                                    <>
                                        <div className={""} onKeyPress={e => handleEnterKey(e)}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Package Details'} />
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div className={'col-span-1'}>
                                                    <label htmlFor="trackingNumber"
                                                        className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Tracking number"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="trackingNumber"
                                                            name="trackingNumber"
                                                            type="text"
                                                            className={"form-field-input"}
                                                            value={packageInfo.trackingNumber}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={'col-span-1'}>
                                                    <label htmlFor="title" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Title"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            className={"form-field-input"}
                                                            value={packageInfo.title}
                                                            required={true}
                                                            autoComplete="title"
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <br />

                                            <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div>
                                                    <label htmlFor="value" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"CIF Value"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="value"
                                                            name="value"
                                                            type="number"
                                                            className={"form-field-input"}
                                                            value={packageInfo.value}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="weight" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Weight"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="weight"
                                                            name="weight"
                                                            type="number"
                                                            className={"form-field-input"}
                                                            value={packageInfo.weight}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="receivedAt" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Date Received"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="receivedAt"
                                                            name="receivedAt"
                                                            type="date"
                                                            className={"form-field-input"}
                                                            value={packageInfo.receivedAt}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <br />

                                            <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div>
                                                    <label htmlFor="handlerId" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Handler ID"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="handlerId"
                                                            name="handlerId"
                                                            type="number"
                                                            className={"form-field-input"}
                                                            value={packageInfo.handlerId}
                                                            required={true}
                                                            onChange={e => handleChange(e)}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="packageNumber" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"AirShip Package Number (APN)"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="packageNumber"
                                                            name="packageNumber"
                                                            type="number"
                                                            className={"form-field-input disabled"}
                                                            value={packageInfo.packageNumber}
                                                            required={true}
                                                            readOnly={true}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="boxNumber" className="block text-sm font-medium text-gray-700 form-field-label">
                                                        <Translate text={"Box Number"} />
                                                    </label>
                                                    <div className="mt-1">
                                                        <Input
                                                            id="boxNumber"
                                                            name="boxNumber"
                                                            type="number"
                                                            className={"form-field-input disabled"}
                                                            value={packageInfo.boxNumber}
                                                            required={true}
                                                            readOnly={true}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <br />

                                            <div>
                                                <div>
                                                    <button
                                                        type="submit"
                                                        className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                        onClick={() => savePackageInfo()}
                                                    >
                                                        <Translate text={"Save"} />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                }

                                {page === 'invoice' &&
                                    <>
                                        <div className={''}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Current Invoice Status'} />:&nbsp;
                                                <strong>{packageInfo.invoiceStatus || 'N/A'}</strong>
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            {!['', null, undefined].includes(packageInfo.invoiceFileKey) &&
                                                <>
                                                    <p className={'text-sm text-gray-700'}>
                                                        <Translate text={"Invoice Key"} />:
                                                    </p>
                                                    <p><a href={`${window.getServiceEndpoint()}/public/fs/bucket/${packageInfo.invoiceFileKey}?${window.getQueryStringHeaders()}`}><Translate text={"Download Invoice"} /></a> &nbsp; <a href="" onClick={async (e) => {
                                                        e.preventDefault();
                                                        let confirm = await Swal.fire({
                                                            title: 'Share Invoice',
                                                            text: 'Are you sure you want to share this invoice with the carrier?',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Yes, share it!',
                                                            cancelButtonText: 'No, cancel!',
                                                            reverseButtons: true
                                                        });
                                                        if (!confirm.isConfirmed) return;
                                                        let body = {packageId: packageInfo._id};
                                                        const response = await Http.request({uri: `/invoices/share`, body});
                                                        if (response.notification) {
                                                          Notifier(response.notification);
                                                        }
                                                    }}
                                                    ><Translate text={"Share Invoice"} /></a></p>
                                                    <br />
                                                    <hr />
                                                    <br />
                                                </>
                                            }

                                            <p className={'text-sm text-gray-700'}>
                                                <Translate text={"Select a file from your device to add or replace an invoice. This file must be a PDF or image (JPG/JPEG/PNG) and 10 megs or less in size."} />
                                            </p>

                                            <br />

                                            <label htmlFor={"invoiceFileChooser"} className={'file-chooser-label'}><ArrowUpTrayIcon className={'inline'} height={"16"} /> <Translate text={"Select a file"} /></label>

                                            <input type="file" id={"invoiceFileChooser"} hidden onChange={selectFile} multiple={false} />

                                        </div>
                                    </>
                                }

                                {page === 'charges' &&
                                    <>
                                        <div className={''}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Charges & Fees'} />
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            <div
                                                className="grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div>
                                                    <p className={'text-blue-800'}><strong><Translate text={'Weight'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        {packageInfo.weight} lbs
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className={'text-blue-800'}><strong><Translate text={'Freight Charges'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={packageInfo.freight} />
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className={'text-green-800'}><strong><Translate text={'CIF Value'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={packageInfo.value} />
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className={'text-green-800'}><strong><Translate text={'Duties'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={packageInfo.duties} />
                                                    </p>
                                                </div>
                                            </div>

                                            <br />
                                            <hr />
                                            <br />

                                            <div
                                                className="grid lg:grid-cols-4 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div>
                                                    <p><strong><Translate text={'Total Charged'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={packageInfo.totalCharged} />
                                                    </p>
                                                </div>

                                                <div>
                                                    <p><strong><Translate text={'Total Paid'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={packageInfo.totalPaid} />
                                                    </p>
                                                </div>

                                                <div>
                                                    <p><strong><Translate text={'Balance'} /></strong></p>
                                                    <p className={'py-3'}>
                                                        <CurrencyText value={packageInfo.totalBalance} />
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                    </>
                                }

                                {page === 'billing' &&
                                    <>
                                        <div className={''}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Billing Actions'} />
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            {!customerId &&
                                                <>
                                                    <Translate
                                                        text={"Billing actions cannot performed because this package is unassigned. Who would pay the charges? You? Lol."} />
                                                </>
                                            }

                                            {customerId &&
                                                <>
                                                    <p className={'text-sm text-gray-700'}>
                                                        <Translate
                                                            text={"Please select a billing action below to perform on this package and/or Member."} />
                                                    </p>

                                                    <br />

                                                    <div
                                                        className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                        <div>
                                                            <p><strong><Translate text={'Update Charges'} /></strong></p>
                                                            <p className={'text-gray-600 py-2'}>
                                                                <Translate
                                                                    text={"Assess all the details on this package and apply charges to this package only."} />
                                                            </p>
                                                            <p className={'py-2'}>
                                                                <button className={'file-chooser-label'}
                                                                    onClick={() => updateCharges()}><PlayIcon
                                                                        className={'inline'} height={"16"} /> <Translate
                                                                        text={"Update charges"} /></button>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <p><strong><Translate text={'Update All Charges'} /></strong></p>
                                                            <p className={'text-gray-600 py-2'}>
                                                                <Translate
                                                                    text={"Assess all the details on this package and all other packages for Member and apply charges."} />
                                                            </p>
                                                            <p className={'py-2'}>
                                                                <button className={'file-chooser-label'}
                                                                    onClick={() => updateAllCharges()}><PlayIcon
                                                                        className={'inline'} height={"16"} /> <Translate
                                                                        text={"Update all charges"} /></button>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <p><strong><Translate text={'Post Credit'} /></strong></p>
                                                            <p className={'text-gray-600 py-2'}>
                                                                <Translate
                                                                    text={"Post a credit amount to the Member's account. A transaction will be created for the Member to see."} />
                                                            </p>
                                                            <div className={'py-2'}>
                                                                <div>
                                                                    <Input
                                                                        id="amount"
                                                                        name="amount"
                                                                        type="text"
                                                                        className={"form-field-input"}
                                                                        value={creditPosting.amount}
                                                                        required={true}
                                                                        onChange={e => handleChangeCreditPosting(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className={'py-2'}>
                                                                <button className={'file-chooser-label'}
                                                                    onClick={() => submitCreditPosting()}><PlayIcon
                                                                        className={'inline'} height={"16"} /> <Translate
                                                                        text={"Post credit"} /></button>
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <p><strong><Translate text={'Post Debit'} /></strong></p>
                                                            <p className={'text-gray-600 py-2'}>
                                                                <Translate
                                                                    text={"Post a debit amount to the Member's account. A transaction will be created for the Member to see."} />
                                                            </p>
                                                            <div className={'py-2'}>
                                                                <div>
                                                                    <Input
                                                                        id="debitAmount"
                                                                        name="amount"
                                                                        type="text"
                                                                        className={"form-field-input"}
                                                                        value={debitPosting.amount}
                                                                        required={true}
                                                                        onChange={e => handleChangeDebitPosting(e)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className={'py-2'}>
                                                                <button className={'file-chooser-label'}
                                                                    onClick={() => submitDebitPosting()}><PlayIcon
                                                                        className={'inline'} height={"16"} /> <Translate
                                                                        text={"Post debit"} /></button>
                                                            </p>
                                                        </div>

                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </>
                                }

                                {page === 'status' &&
                                    <>
                                        <div className={''}>

                                            <h2 className={'page-sub-title'}>
                                                <Translate text={'Package Status'} />
                                            </h2>

                                            <br />
                                            <hr />
                                            <br />

                                            <div
                                                className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                                <div>
                                                    <p><strong><Translate text={'Movement & Location'} /></strong></p>
                                                    <p className={'text-gray-600 py-2'}>
                                                        <Translate
                                                            text={"Current movement status is:"} />&nbsp;{F.getListValue(originalPackageInfo.movementStatus, movementStatuses)}
                                                    </p>
                                                    <div>
                                                        <Listbox value={packageInfo.movementStatus} onChange={setMovementStatus}>
                                                            {({ open }) => (
                                                                <>
                                                                    <div className="relative mt-1">
                                                                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                                                            <span className="block truncate">{F.getListValue(packageInfo.movementStatus, movementStatuses)}</span>
                                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                            </span>
                                                                        </Listbox.Button>

                                                                        <Transition
                                                                            show={open}
                                                                            as={Fragment}
                                                                            leave="transition ease-in duration-100"
                                                                            leaveFrom="opacity-100"
                                                                            leaveTo="opacity-0"
                                                                        >
                                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                                {movementStatuses.map((movement) => (
                                                                                    <Listbox.Option
                                                                                        key={movement.value}
                                                                                        className={({ active }) =>
                                                                                            classNames(
                                                                                                active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                            )
                                                                                        }
                                                                                        value={movement.value}
                                                                                    >
                                                                                        {({ selected, active }) => (
                                                                                            <>
                                                                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                                    {F.getListValue(movement.value, movementStatuses)}
                                                                                                </span>

                                                                                                {selected ? (
                                                                                                    <span
                                                                                                        className={classNames(
                                                                                                            active ? 'text-white' : 'text-blue-600',
                                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                        )}
                                                                                                    >
                                                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                    </span>
                                                                                                ) : null}
                                                                                            </>
                                                                                        )}
                                                                                    </Listbox.Option>
                                                                                ))}
                                                                            </Listbox.Options>
                                                                        </Transition>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox>

                                                    </div>
                                                    <p className={'py-2'}>
                                                        <button className={'file-chooser-label'}
                                                            onClick={() => updateStatus('movementStatus')}><PlayIcon
                                                                className={'inline'} height={"16"} /> <Translate
                                                                text={"Update Movement Status"} /></button>
                                                    </p>
                                                </div>

                                                <div>
                                                    <p><strong><Translate text={'Status on ETA'} /></strong></p>
                                                    <p className={'text-gray-600 py-2'}>
                                                        <Translate
                                                            text={"Current eta status is:"} />&nbsp;{F.getListValue(originalPackageInfo.etaStatus, etaStatuses)}
                                                    </p>
                                                    <div>
                                                        <Listbox value={packageInfo.etaStatus} onChange={setEtaStatus}>
                                                            {({ open }) => (
                                                                <>
                                                                    <div className="relative mt-1">
                                                                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                                                            <span className="block truncate">{F.getListValue(packageInfo.etaStatus, etaStatuses)}</span>
                                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                            </span>
                                                                        </Listbox.Button>

                                                                        <Transition
                                                                            show={open}
                                                                            as={Fragment}
                                                                            leave="transition ease-in duration-100"
                                                                            leaveFrom="opacity-100"
                                                                            leaveTo="opacity-0"
                                                                        >
                                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                                {etaStatuses.map((eta) => (
                                                                                    <Listbox.Option
                                                                                        key={eta.value}
                                                                                        className={({ active }) =>
                                                                                            classNames(
                                                                                                active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                            )
                                                                                        }
                                                                                        value={eta.value}
                                                                                    >
                                                                                        {({ selected, active }) => (
                                                                                            <>
                                                                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                                    {F.getListValue(eta.value, etaStatuses)}
                                                                                                </span>

                                                                                                {selected ? (
                                                                                                    <span
                                                                                                        className={classNames(
                                                                                                            active ? 'text-white' : 'text-blue-600',
                                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                        )}
                                                                                                    >
                                                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                    </span>
                                                                                                ) : null}
                                                                                            </>
                                                                                        )}
                                                                                    </Listbox.Option>
                                                                                ))}
                                                                            </Listbox.Options>
                                                                        </Transition>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox>

                                                    </div>
                                                    <p className={'py-2'}>
                                                        <button className={'file-chooser-label'}
                                                            onClick={() => updateStatus('etaStatus')}><PlayIcon
                                                                className={'inline'} height={"16"} /> <Translate
                                                                text={"Update Eta Status"} /></button>
                                                    </p>
                                                </div>

                                                <div>
                                                    <p><strong><Translate text={'Invoice Status'} /></strong></p>
                                                    <p className={'text-gray-600 py-2'}>
                                                        <Translate
                                                            text={"Current invoice status is:"} />&nbsp;{F.getListValue(originalPackageInfo.invoiceStatus, invoiceStatuses)}
                                                    </p>
                                                    <div>
                                                        <Listbox value={packageInfo.invoiceStatus} onChange={setInvoiceStatus}>
                                                            {({ open }) => (
                                                                <>
                                                                    <div className="relative mt-1">
                                                                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                                                            <span className="block truncate">{F.getListValue(packageInfo.invoiceStatus, invoiceStatuses)}</span>
                                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                            </span>
                                                                        </Listbox.Button>

                                                                        <Transition
                                                                            show={open}
                                                                            as={Fragment}
                                                                            leave="transition ease-in duration-100"
                                                                            leaveFrom="opacity-100"
                                                                            leaveTo="opacity-0"
                                                                        >
                                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                                {invoiceStatuses.map((invoice) => (
                                                                                    <Listbox.Option
                                                                                        key={invoice.value}
                                                                                        className={({ active }) =>
                                                                                            classNames(
                                                                                                active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                            )
                                                                                        }
                                                                                        value={invoice.value}
                                                                                    >
                                                                                        {({ selected, active }) => (
                                                                                            <>
                                                                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                                    {F.getListValue(invoice.value, invoiceStatuses)}
                                                                                                </span>

                                                                                                {selected ? (
                                                                                                    <span
                                                                                                        className={classNames(
                                                                                                            active ? 'text-white' : 'text-blue-600',
                                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                        )}
                                                                                                    >
                                                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                    </span>
                                                                                                ) : null}
                                                                                            </>
                                                                                        )}
                                                                                    </Listbox.Option>
                                                                                ))}
                                                                            </Listbox.Options>
                                                                        </Transition>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox>

                                                    </div>
                                                    <p className={'py-2'}>
                                                        <button className={'file-chooser-label'}
                                                            onClick={() => updateStatus('invoiceStatus')}><PlayIcon
                                                                className={'inline'} height={"16"} /> <Translate
                                                                text={"Update Invoice Status"} /></button>
                                                    </p>
                                                </div>

                                                <div>
                                                    <p><strong><Translate text={'Payment Status'} /></strong></p>
                                                    <p className={'text-gray-600 py-2'}>
                                                        <Translate
                                                            text={"Current payment status is:"} />&nbsp;{F.getListValue(originalPackageInfo.paymentStatus, paymentStatuses)}
                                                    </p>
                                                    <div>
                                                        <Listbox value={packageInfo.paymentStatus} onChange={setPaymentStatus}>
                                                            {({ open }) => (
                                                                <>
                                                                    <div className="relative mt-1">
                                                                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                                                            <span className="block truncate">{F.getListValue(packageInfo.paymentStatus, paymentStatuses)}</span>
                                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                            </span>
                                                                        </Listbox.Button>

                                                                        <Transition
                                                                            show={open}
                                                                            as={Fragment}
                                                                            leave="transition ease-in duration-100"
                                                                            leaveFrom="opacity-100"
                                                                            leaveTo="opacity-0"
                                                                        >
                                                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                                {paymentStatuses.map((payment) => (
                                                                                    <Listbox.Option
                                                                                        key={payment.value}
                                                                                        className={({ active }) =>
                                                                                            classNames(
                                                                                                active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                            )
                                                                                        }
                                                                                        value={payment.value}
                                                                                    >
                                                                                        {({ selected, active }) => (
                                                                                            <>
                                                                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                                    {F.getListValue(payment.value, paymentStatuses)}
                                                                                                </span>

                                                                                                {selected ? (
                                                                                                    <span
                                                                                                        className={classNames(
                                                                                                            active ? 'text-white' : 'text-blue-600',
                                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                        )}
                                                                                                    >
                                                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                    </span>
                                                                                                ) : null}
                                                                                            </>
                                                                                        )}
                                                                                    </Listbox.Option>
                                                                                ))}
                                                                            </Listbox.Options>
                                                                        </Transition>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Listbox>

                                                    </div>
                                                    <p className={'py-2'}>
                                                        <button className={'file-chooser-label'}
                                                            onClick={() => updateStatus('paymentStatus')}><PlayIcon
                                                                className={'inline'} height={"16"} /> <Translate
                                                                text={"Update Payment Status"} /></button>
                                                    </p>
                                                </div>


                                            </div>

                                        </div>
                                    </>
                                }

                            </div>

                            <div className={"col-span-1 bg-gray-200 p-5 shadow rounded-lg"}>
                                <h2 className={'page-sub-title'}><Translate text={"Member Info"} /></h2>
                                <br />
                                <hr />
                                <br />
                                {!customerId &&
                                    <>
                                        <div>
                                            <p className={'form-field-label text-md text-blue-800'}><Translate text={"Name on package"} />:</p>
                                            {packageInfo.meta?.memberName}
                                        </div>
                                        <br />
                                        <div>
                                            <p><strong><Translate text={'Assign a Member'} /></strong></p>
                                            <p className={'text-gray-600 py-2'}>
                                                <Translate text={"This package does not belong to any Member right now. Enter a BOX number below to assign the package."} />
                                            </p>
                                            <div className={'py-2'}>
                                                <div>
                                                    <Input
                                                        id="boxNumber"
                                                        name="boxNumber"
                                                        type="number"
                                                        className={"form-field-input"}
                                                        value={assignment.boxNumber}
                                                        required={true}
                                                        onChange={e => handleChangeAssign(e)}
                                                    />
                                                </div>
                                            </div>
                                            <p className={'py-2'}>
                                                <button className={'rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 primary-btn'} onClick={() => submitBoxAssignment()}><Translate text={"Assign Package"} /></button>
                                            </p>
                                        </div>
                                    </>
                                }
                                {customerId && <>
                                    <div>
                                        <p className={'form-field-label text-md text-blue-800'}><Translate text={"BOX Number"} />:</p>
                                        <a href={`/members/${customerId}/manage`} target={"_blank"}>{memberInfo.boxNumber}</a>
                                    </div>
                                    <br />
                                    <div>
                                        <p className={'form-field-label text-md text-blue-800'}><Translate text={'Name'} />:</p>
                                        {memberInfo.firstName}&nbsp;{memberInfo.lastName}
                                    </div>
                                    <br />
                                    <div>
                                        <p className={'form-field-label text-md text-blue-800'}><Translate text={'Email Address'} />:</p>
                                        {memberInfo.emailAddress || 'N/A'}
                                    </div>
                                    <br />
                                    <div>
                                        <p className={'form-field-label text-md text-blue-800'}><Translate text={'Phone Number'} />:</p>
                                        {memberInfo.phoneNumber || 'N/A'}
                                    </div>
                                    <br />
                                    <div>
                                        <p className={'form-field-label text-md text-blue-800'}><Translate text={'Registration Date'} />:</p>
                                        <DateText value={memberInfo.createdAt} />
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}