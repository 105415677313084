import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Login from "./Login";
import Logout from "./Logout";

export default function Auth() {
    return (
      <>
      <main>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />
        </Routes>
      </main>
      </>
    );
  }