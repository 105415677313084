import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, Input, Paging, RelativeDate} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon, CheckIcon, ChevronUpDownIcon,
  CreditCardIcon,
  CurrencyDollarIcon, GlobeAltIcon,
  InformationCircleIcon, LockClosedIcon, PaperAirplaneIcon, PencilIcon, PlayCircleIcon, PlusCircleIcon,
  ScaleIcon, StopCircleIcon, TruckIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import axios from "axios";
import {Helmet} from "react-helmet";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import {Listbox, Transition} from "@headlessui/react";
import F from "../../../../../Utils/Functions";
import {useParams} from "react-router-dom";

export default function RouteHandler(props) {

  let { handlerId } = useParams();
  let { action } = props;

  useEffect(() => {
    if ( action === 'viewInvoice' ) {
      getInvoiceLink({handlerId});
    }
  }, []);

  const getInvoiceLink = async args => {
    let body = {...args};
    const response = await Http.request({uri: `/packages/get-invoice-link`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      if ( response.body.invoiceLink ) {
        window.location.assign(response.body.invoiceLink);
      }
    } else {
      Notifier({type: 'error', text: 'Failed to get invoice link.'});
    }
  };

  return (
      <>
        <div className="flex min-h-full">
          Please wait ...
        </div>
      </>
  );
}