import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, Paging, RelativeDate, ShortenInText} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon, CheckIcon,
  CreditCardIcon,
  CurrencyDollarIcon, EyeIcon,
  InformationCircleIcon, PlusCircleIcon,
  ScaleIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import SettingsMenu from "../components/PackagesMenu";
import PackagesMenu from "../components/PackagesMenu";
import axios from "axios";
import {Helmet} from "react-helmet";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Browse(props) {

  useDocumentTitle('Packages');

  const [packages, setPackages] = useState([]);
  const [invoiceMode, setInvoiceMode] = useState(false);
  const [invoiceEditTarget, setInvoiceEditTarget] = useState(null);
  const [pagination, setPagination] = useState({});
  const [query, setQuery] = useState({});
  const [keywords, setKeywords] = useState('');

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async (p, q) => {
    // console.log('paging fetch', pagination)
    let body = {paging: p || pagination, keywords, query: q || query};
    const response = await Http.request({uri: `/packages/browse`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setPackages(response.body.packages);
    setPagination(response.body.pagination);
  }

  const applyCharges = async (packageId) => {
    let body = {packageId};
    const response = await Http.request({uri: `/payments/${packageId}/update-charges`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    fetchPackages(pagination, query);
  };

  const applyAllCharges = async (customerId) => {
    let body = {customerId};
    const response = await Http.request({uri: `/payments/${customerId}/update-all-charges`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    fetchPackages(pagination, query);
  };

  const cancelCharges = async (packageId) => {
    let body = {packageId};
    const response = await Http.request({uri: `/payments/${packageId}/cancel-charges`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    fetchPackages(pagination, query);
  };

  const cancelAllCharges = async (customerId) => {
    let body = {customerId};
    const response = await Http.request({uri: `/payments/${customerId}/cancel-unregistered-charges`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    fetchPackages(pagination, query);
  };

  const markDelivered = async (packageId) => {
    let body = {packageId, fieldName: 'movementStatus', fieldValue: 'delivered'};
    const response = await Http.request({uri: `/packages/${packageId}/set-status`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    fetchPackages(pagination, query);
  };

  const addToDeliveryQueue = async (packageId) => {
    let body = {packageId, fieldName: 'movementStatus', fieldValue: 'delivering'};
    const response = await Http.request({uri: `/packages/${packageId}/set-status`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    fetchPackages();
  };

  const statusDefinitions = window.getConfig('STATUS_DEFINITIONS') || {};
  const getStatusStyle = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.style || `${status}`;
  }

  const getStatusName = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.label || `${status}`;
  }

  const onChangeKeywords = e => setKeywords(e.target.value);

  const updatePaging = p => {
    // console.log('paging call', p)
    setPagination(p);
  }

  const paging = <Paging
      pagination={pagination}
      pagingUpdate={updatePaging}
      pagingAction={fetchPackages}
      searchUpdate={onChangeKeywords}
      keywords={keywords}
  />

  const filters = [
    {label: 'All', value: 'all'},
    {label: 'New', value: 'new'},
    {label: 'Warehouse', value: 'warehouse'},
    {label: 'In Flight', value: 'inflight'},
    {label: 'Clearing', value: 'customs'},
    {label: 'Cleared', value: 'cleared'},
    {label: 'Ready', value: 'ready'},
    {label: 'Delivering', value: 'delivering'},
    {label: 'Delivered', value: 'delivered'},
    {label: 'Removed', value: 'removed'},
  ];

  const [activeFilter, setActiveFilter] = useState('');
  const toggleActiveFilter = filter => {
    let q = {...query};
    if ( activeFilter === filter || !filter ) {
      setActiveFilter('');
      if ( q.movementStatus ) delete q.movementStatus;
      fetchPackages(pagination, q);
    } else {
      setActiveFilter(filter);
      q.movementStatus = filter;
      fetchPackages(pagination, q);
    }
  };

  const filterBar = <>
    {filters.map((filter, filterKey) => (
        <Fragment key={filterKey}>
          <span className={`clickable inline-flex items-center rounded-full px-4 py-1 text-sm font-medium ${activeFilter !== filter.value ? 'text-black bg-blue-200' : 'text-white bg-indigo-400'}`} onClick={() => toggleActiveFilter(filter.value)}>
            <Translate text={filter.label} />
          </span> &nbsp;&nbsp;
        </Fragment>
    ))}
  </>;

  const emptyRows = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Packages"}></Header>
            <br />
            <Container>
              <div className="">
                <div className="flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      {filterBar}
                      <br /><br />
                      {paging}
                      <div className="mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-white divide-y divide-x divide-gray-200">
                          <tr className={'divide-x divide-gray-300'}>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"APN"} />
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"Handler#"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Member"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Title"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Tracking"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Weight"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Status"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Balance"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Date"} />
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">&nbsp;</span>
                            </th>
                          </tr>
                          </thead>
                          <tbody className="divide-y divide-x divide-gray-200">
                          {packages.map((packageInfo, packageIndex) => (
                              <tr key={packageInfo.packageNumber} className={`divide-x divide-gray-300 ${packageIndex % 2 === 0 ? undefined : 'bg-white'}`}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  <a href={`/packages/${packageInfo._id}/manage`}>{packageInfo.packageNumber}</a>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.handlerId}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  {packageInfo.customerId && <a href={`/members/${packageInfo.customerId}/manage`}>
                                    {packageInfo.boxNumber} - {(packageInfo.meta?.memberName || '').split(' ')[0]}
                                  </a>}
                                  {!packageInfo.customerId && <>{packageInfo.meta?.memberName}</>}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><ShortenInText text={packageInfo.title} maxLength={24} /></td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><ShortenInText text={packageInfo.trackingNumber} />{packageInfo.trackingNumber.indexOf('NO-TRACKING') < 0 && <>&nbsp;<a href={`https://parcelsapp.com/en/tracking/${packageInfo.trackingNumber}`} target={"_blank"} title={"Track this Package"}><EyeIcon height={"16"} className={"inline"} /></a></>}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.weight} {packageInfo.weight !== 1 ? 'lbs' : 'lb'} </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.movementStatus}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><CurrencyText value={packageInfo.totalBalance} /></td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><DateText value={packageInfo.createdAt} /></td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                      <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                        <span className="sr-only">Open options</span>
                                        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                      </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style={{zIndex: 10000}}>
                                        <div className="py-1">
                                          <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href={`/packages/${packageInfo._id}/manage`}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                  Open
                                                </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => applyCharges(packageInfo._id)}
                                                >
                                                  Apply Charges
                                                </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => applyAllCharges(packageInfo.customerId)}
                                                >
                                                  Apply All Charges
                                                </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => cancelCharges(packageInfo._id)}
                                                >
                                                  Cancel Charges
                                                </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => cancelAllCharges(packageInfo.customerId)}
                                                >
                                                  Cancel All Charges
                                                </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => markDelivered(packageInfo._id)}
                                                >
                                                  Mark Delivered
                                                </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => addToDeliveryQueue(packageInfo._id)}
                                                >
                                                  Add to Delivery Queue
                                                </a>
                                            )}
                                          </Menu.Item>
                                          {packageInfo.invoiceFileKey &&
                                              <Menu.Item>
                                                {({active}) => (
                                                    <a
                                                        href={`${window.getServiceEndpoint()}/public/fs/bucket/${packageInfo.invoiceFileKey}?${window.getQueryStringHeaders()}`}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                      View Invoice
                                                    </a>
                                                )}
                                              </Menu.Item>
                                          }
                                          {!packageInfo.invoiceFileKey &&
                                              <Menu.Item>
                                                {({active}) => (
                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No invoice is here</span>
                                                )}
                                              </Menu.Item>
                                          }
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </td>
                              </tr>
                          ))}
                          {emptyRows.map((emptyRow, i) => (
                              <tr key={i + packages.length} className={`divide-x divide-gray-300 ${(i + packages.length) % 2 === 0 ? undefined : 'bg-white'}`}>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                      <br />
                      {pagination.count > 10 && <>{paging}</>}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>

      </>
  );
}