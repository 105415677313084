import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, NumberText, Paging, RelativeDate} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  InformationCircleIcon, PlusCircleIcon,
  ScaleIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import axios from "axios";
import {Helmet} from "react-helmet";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";

export default function Browse(props) {

  useDocumentTitle('Members');

  const [customers, setCustomers] = useState([]);
  const [invoiceMode, setInvoiceMode] = useState(false);
  const [invoiceEditTarget, setInvoiceEditTarget] = useState(null);
  const [pagination, setPagination] = useState({});
  const [keywords, setKeywords] = useState('');

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async p => {
    if ( p ) setPagination(p);
    console.log(p, pagination)
    let body = {paging: p || pagination, keywords};
    const response = await Http.request({uri: `/customers/browse`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setCustomers(response.body.customers);
    setPagination(response.body.pagination);
  }

  const statusDefinitions = window.getConfig('STATUS_DEFINITIONS') || {};
  const getStatusStyle = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.style || `${status}`;
  }

  const getStatusName = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.label || `${status}`;
  }

  const onChangeKeywords = e => setKeywords(e.target.value);

  const updatePaging = p => {
    console.log('paging call', p)
    setPagination(p);
  }

  const handleSort = field => {
    let currentSort = pagination.sort || [];
    if ( currentSort[0] === field ) {
      currentSort[1] = currentSort[1] === 'asc' ? 'desc' : 'asc';
    } else {
      currentSort = [field, 'asc'];
    }
    let p = {...pagination, sort: currentSort};
    setPagination(p);
    fetchMembers(p);
  }

  const paging = <Paging
      pagination={pagination}
      pagingUpdate={updatePaging}
      pagingAction={fetchMembers}
      searchUpdate={onChangeKeywords}
      keywords={keywords}
  />

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Members"}></Header>
            <br />
            <Container>
              <div className="">
                <div className="flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      {paging}
                      <div className="mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-white divide-y divide-x divide-gray-200">
                          <tr className={'divide-x divide-gray-300'}>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-8%" onClick={() => handleSort('boxNumber')}>
                              <Translate text={"BOX"} /><span className="float-right">{pagination?.sort?.join(',') === 'boxNumber,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'boxNumber,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-20%" onClick={() => handleSort('firstName')}>
                              <Translate text={"Member Name"} /><span className="float-right">{pagination?.sort?.join(',') === 'firstName,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'firstName,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-20%" onClick={() => handleSort('emailAddress')}>
                              <Translate text={"Email Address"} /><span className="float-right">{pagination?.sort?.join(',') === 'emailAddress,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'emailAddress,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-12%" onClick={() => handleSort('phoneNumber')}>
                              <Translate text={"Phone Number"} /><span className="float-right">{pagination?.sort?.join(',') === 'phoneNumber,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'phoneNumber,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-8%" onClick={() => handleSort('statement.balance')}>
                              <Translate text={"Balance"} /><span className="float-right">{pagination?.sort?.join(',') === 'statement.balance,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'statement.balance,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-8%" onClick={() => handleSort('statement.points')}>
                              <Translate text={"Points"} /><span className="float-right">{pagination?.sort?.join(',') === 'statement.points,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'statement.points,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-8%" onClick={() => handleSort('createdAt')}>
                              <Translate text={"Registered"} /><span className="float-right">{pagination?.sort?.join(',') === 'createdAt,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'createdAt,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-8%" onClick={() => handleSort('lastSeenAt')}>
                              <Translate text={"Last Seen"} /><span className="float-right">{pagination?.sort?.join(',') === 'lastSeenAt,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'lastSeenAt,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900 clickable pt-5 w-8%" onClick={() => handleSort('status')}>
                              <Translate text={"Status"} /><span className="float-right">{pagination?.sort?.join(',') === 'status,desc' ? <ChevronDoubleDownIcon className={'inline-block w-4 h-4'} /> : ''}
                              {pagination?.sort?.join(',') === 'status,asc' ? <ChevronDoubleUpIcon className={'inline-block w-4 h-4'} /> : ''}</span>
                            </th>
                          </tr>
                          </thead>
                          <tbody className="divide-y divide-x divide-gray-200">
                          {customers.map((customerInfo, packageIndex) => (
                              <tr key={customerInfo.boxNumber} className={`divide-x divide-gray-300 ${packageIndex % 2 === 0 ? undefined : 'bg-white'}`}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {customerInfo.boxNumber}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  <a href={`/members/${customerInfo._id}/manage`}>{customerInfo.firstName}&nbsp;{customerInfo.lastName}</a>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  {customerInfo.emailAddress}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  {customerInfo.phoneNumber}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  <CurrencyText value={customerInfo.statement?.balance} />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  <NumberText value={customerInfo.statement?.points} />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  <DateText date={customerInfo.createdAt} />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  <RelativeDate date={customerInfo.lastSeenAt} showOnline={true} />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                  <span className={`inline-block rounded-md p-1 px-3 text-xs ${customerInfo.status === 'active' ? 'bg-blue-200' : 'bg-gray-200'} `}>{(customerInfo.status || "").toTitleCase()}</span>
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                      <br />
                      {pagination.count > 10 && <>{paging}</>}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>

      </>
  );
}