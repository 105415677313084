import moment from "moment";
import React, {Fragment, useEffect, useState} from 'react';
import NumberFormat from "react-number-format";
import Translate from "./Translate";
import {ArrowPathIcon} from "@heroicons/react/20/solid";

export function Input (props) {
    return (
        <>
            <input
                id={props.id}
                name={props.name}
                type={props.type || "text"}
                value={props.value}
                placeholder={props.placeHolder}
                autoComplete={props.autoComplete}
                required={props.required}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
            />
        </>
    )
}

export class DateText extends React.Component {
    render() {
        let value = this.props.value || this.props.date;
        let format = this.props.format || "MMM D, YYYY";
        if ( this.props.showTime ) {
            format = "MMM D, YYYY h:mm:ss A";
        }
        let dateValue = moment(value).format(format);
        if ( !value ) {
            dateValue = 'N/A';
        }

        return (
            <span title={this.props.value}>{dateValue}</span>
        )
    }
}

export class RelativeDate extends React.Component {
    render() {
        let dateValue = this.props.value || this.props.date;
        let timeElapsed = moment(new Date).diff(moment(dateValue), 'seconds');// + 5*60*60;
        let suffix = '';
        let relativeDate = moment(dateValue).format("MMMM D, YYYY LT");
        if ( this.props.dateOnly ) {
            relativeDate = moment(dateValue).format("LL");
        }

        if ( timeElapsed < 0 ) {
            relativeDate = '-';
        } else if ( timeElapsed < 60 ) {
            relativeDate = this.props.showOnline? 'Online': 'Now';
        } else if ( timeElapsed < 120 ) {
            relativeDate = this.props.showOnline? 'Online': 'Now';
        } else if ( timeElapsed < 3600 ) {
            relativeDate = Math.round(timeElapsed/60);
            suffix = relativeDate !== 1 ? `mins` : `min`;
        } else if ( timeElapsed < 3660 ) {
            relativeDate = 'an hour ago';
        } else if ( timeElapsed < 86400 ) {
            relativeDate = (timeElapsed/3600).toFixed(0);
            suffix = relativeDate !== 1 ? `hrs` : `hr`;
        } else if ( timeElapsed < 172800 ) {
            relativeDate = '';
            suffix = 'Yesterday';
        } else if ( timeElapsed < 604800 ) {
            relativeDate = Math.round(timeElapsed/86400);
            suffix = relativeDate !== 1 ? `days` : `day`;
        } else if ( timeElapsed < 604800 * 4 ) {
            relativeDate = Math.round(timeElapsed/86400/7);
            suffix = relativeDate !== 1 ? `wks` : `wk`;
        } else if ( timeElapsed < 604800 * 4 * 12 ) {
            relativeDate = Math.round(timeElapsed/86400/7/4);
            suffix = relativeDate !== 1 ? `mths` : `mth`;
        } else {
            relativeDate = Math.round(timeElapsed/86400/7/4/12);
            suffix = relativeDate !== 1 ? `yrs` : `yr`;
        }

        if ( this.props.titleCase ) {
            suffix = ( suffix || '' ).toTitleCase();
        } else {
            relativeDate = ( relativeDate || '').toString().toLowerCase();
        }

        const dateText = !this.props.dateOnly ?  ( dateValue ? <>
                <span
                    title={moment(dateValue).format("MMMM D, YYYY HH:mm:ss TZ")}>
                    {relativeDate}{relativeDate && <>&nbsp;</>}{suffix}
                </span>
        </> : null ) : moment(dateValue).format('LL');

        return (
            <>
                {dateText}
            </>
        )
    }
}

export class CurrencyText extends React.Component {
    render() {
        let currency = '$', value = this.props.value || 0;
        if ( value < 0 ) {
            currency = '-$';
            value = value * -1;
        }
        let negativeClass = '';

        if ( this.props.value < 0 ) {
            negativeClass = 'negative';
        }

        let suffix = '', decimalScale = 2, fixedDecimalScale = true;

        if ( this.props.shorten ) {
            if ( Math.abs(value) >= 1000000000 ) {
                value /= 1000000000;
                suffix = 'b';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if ( Math.abs(value) >= 1000000 ) {
                value /= 1000000;
                suffix = 'm';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if ( Math.abs(value) >= 10000 ) {
                value /= 1000;
                suffix = 'k';
                decimalScale = 1;
                fixedDecimalScale = false;
            }

            // value = value.toFixed(1);
        }

        fixedDecimalScale = fixedDecimalScale && Math.round(value) !== value || this.props.fixedDecimalScale;

        return (
            <>
                {value > 0 &&
                    <span className={negativeClass + " text-right"}>
                    <NumberFormat
                        value={value}
                        decimalScale={decimalScale}
                        fixedDecimalScale={fixedDecimalScale}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currency}
                        suffix={suffix}
                        autoComplete={"off"}
                        title={this.props.value}
                    />
                </span>
                }
                {!value &&
                    <>
                        {this.props.default || '$0'}
                    </>}
            </>
        )
    }
}

export class NumberText extends React.Component {
    render() {
        let negativeClass = '';
        let value = this.props.value || 0;

        if ( value < 0 ) {
            negativeClass = 'negative';
        }

        let suffix = '', decimalScale = 2, fixedDecimalScale = true;

        if ( this.props.shorten ) {
            if ( Math.abs(value) >= 1000000000 ) {
                value /= 1000000000;
                suffix = 'b';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if ( Math.abs(value) >= 1000000 ) {
                value /= 1000000;
                suffix = 'm';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if ( Math.abs(value) >= 10000 ) {
                value /= 1000;
                suffix = 'k';
                decimalScale = 1;
                fixedDecimalScale = false;
            }

            // value = value.toFixed(1);
        }

        fixedDecimalScale = fixedDecimalScale && Math.round(value) !== value || this.props.fixedDecimalScale;

        return (
            <>
                {value > 0 &&
                    <span className={negativeClass + " text-right"}>
                    <NumberFormat
                        value={value}
                        decimalScale={decimalScale}
                        fixedDecimalScale={fixedDecimalScale}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={''}
                        suffix={suffix}
                        autoComplete={"off"}
                        title={this.props.value}
                    />
                </span>
                }
                {!value &&
                    <>
                        {this.props.default || '0'}
                    </>}
            </>
        )
    }
}

export function Paging (props) {

    const [page, setPage] = useState({page: 1, count: 0, pageCount: 1, start: 1, end: 1});

    useEffect(() => {
        setPage(props.pagination);
        console.log(props.pagination);
    }, [props]);

    const getPage = delta => {
        let newPage = Math.max(Math.min(page.page + delta, page.pageCount), 1);
        let pagingUpdate = {...page, page: newPage};
        console.log(pagingUpdate)
        props.pagingUpdate(pagingUpdate);
        props.pagingAction(pagingUpdate);
    }

    const handleEnterKeyKeywords = e => {
        console.log(e.key)
        if ( e.key === 'Enter' ) {
            props.pagingAction();
        }
    }

    return (
        <>
            <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
            >
                <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                        <Translate text={"Showing"} />{' '}
                        <span className="font-medium">{Math.max(0, Math.min(page?.start || 0, page?.count || 0))}</span>{' '}<Translate text={"to"} />{' '}<span className="font-medium">{page?.end || 0}</span>{' '}<Translate text={'of'} />{' '}
                        <span className="font-medium">{page?.count || 0}</span> <Translate text={page?.count !== 1 ? 'results' : 'result'} />
                        &nbsp;&nbsp;
                        <span className={'clickable'} onClick={() => props.pagingAction()}>
                            <ArrowPathIcon height={"22"} className={"inline"} />
                        </span>
                    </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-center px-10">
                    <Input type={"text"} value={props.keywords} onChange={props.searchUpdate} onKeyPress={e => handleEnterKeyKeywords(e)} placeHolder={"ENTER search keywords"} />
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                    <a
                        onClick={() => getPage(-1)}
                        className="clickable relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                        <Translate text={"Previous"} />
                    </a>
                    <a
                        onClick={() => getPage(1)}
                        className="clickable relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                        <Translate text={"Next"} />
                    </a>
                </div>
            </nav>
        </>
    )
}

export function ShortenInText(props) {

    let {text, maxLength} = props;

    let shortenedText = `${text}`;

    if ( !maxLength ) maxLength = 20;

    let length = text?.length || 0;

    if ( length > maxLength ) {
        let firstStop = Math.max(9, ( maxLength / 2 ) - 2 );
        let secondStop = Math.min(length - 1, length - firstStop + 2 );
        let firstString = text.split('').splice(0, firstStop).join('');
        let secondString = text.split('').splice(secondStop, length - firstStop).join('');
        shortenedText = `${firstString}...${secondString}`;
    }

    const [shortened, setShortened] = useState(true);

    return (
        <>
            {shortened && <span className={'clickable'} onClick={() => setShortened(false)} title={text}>{shortenedText}</span>}
            {!shortened && <span className={'clickable'} onClick={() => setShortened(true)}>{text}</span>}
        </>
    )
}
