import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Browse from "./pages/Browse";
import Manage from "./pages/Manage";

export default function Members() {
    return (
      <main>
        <Routes>
            <Route path="/browse" element={<Browse mode={"new"} />} />
            <Route path="/create" element={<Browse mode={"new"} />} />
            <Route path="/:customerId/manage" element={<Manage />} />
            <Route path="/:customerId/manage/:pageId" element={<Manage />} />
            <Route path="*" element={<Browse />} />
        </Routes>
      </main>
    );
  }