import { render } from "react-dom";
import App from "./App";
import { createRoot } from 'react-dom/client';

if ( window.location.host.indexOf('box.c5office.com') > -1 && window.location.protocol.indexOf('https') < 0 ) {
  window.location.assign(`https://${window.location.host}${window.location.pathname}`);
}

window.setConfig = async () => {
  let configHost = process.env.REACT_APP_API_CONFIG_HOST;
  const response = await fetch(configHost).then(response => response.json());
  localStorage.setItem('HOST_CONFIG', JSON.stringify(response));
};

window.getConfig = key => {
  let configData = {};
  let configString = localStorage.getItem('HOST_CONFIG');
  if ( configString ) configData = JSON.parse(configString);
  configData = {...configData, ...process.env};
  return configData[key] || configData[`REACT_APP_API_${key}`] || configData[key.replace('REACT_APP_API_', '')] || null;
};

window.getServiceEndpoint = service => {
  let envQualifier = 'NON_PROD';
  if ( window.location.host.indexOf('local') > 0 ) envQualifier = 'LOCAL';
  if ( window.location.host.indexOf('box.c5office.com') === 0 ) envQualifier = 'PROD';
  if ( window.location.host.indexOf('box-qa.c5office.com') === 0 ) envQualifier = 'QA';
  if ( window.location.host.indexOf('box-dev.c5office.com') === 0 ) envQualifier = 'dev';
  let finalConfigKey = `${(service || 'AIRSHIP_BACK_OFFICE_API').toUpperCase()}_HOST_${envQualifier}`;
  return `${window.getConfig(finalConfigKey)}/admin`;
};

window.getAxiosConfig = () => {
  let headers = {access_token: localStorage.getItem('access_token')};
  return {headers};
};

window.getQueryStringHeaders = () => {
  let headers = window.getAxiosConfig().headers;
  let queryStringList = [];
  for ( let h in headers ) {
    if ( headers.hasOwnProperty(h) ) {
      queryStringList.push(`${h}=${encodeURIComponent(headers[h])}`);
    }
  }
  return queryStringList.join('&');
}

window.titleCase = function(string) {
  let words = `${string || ''}`.split(' ');
  let transformedWords = words.map(item => window.friendlyCase(item));
  return transformedWords.join(' ');
};

window.friendlyCase = function(string) {
  let result = string.replace( /([A-Z])/g, " $1" );
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  let knownWords = {
    "Trn": 'TRN',
    "Date Of Birth": 'Date of Birth',
  }
  for ( let w in knownWords ) {
    if ( finalResult === w ) {
      return knownWords[w];
    }
  }
  return finalResult;
};

String.prototype.toFriendlyCase = function() {
  return window.friendlyCase(this);
};

String.prototype.toTitleCase = function() {
  return window.titleCase(this);
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <App />
);