import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import {Fragment, useEffect, useState} from "react";
// import Header from "../../../../../Components/Header";
import {
    ArrowUpTrayIcon,
    DocumentCheckIcon,
    InformationCircleIcon,
    PlayIcon,
    TrashIcon
} from '@heroicons/react/20/solid';
import Http from "../../../../Utils/Http";
import Notifier from "../../../../Utils/Notifier";
import Container from "../../../../Components/Container";
import {CurrencyText, DateText, Input} from "../../../../Utils/Template";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Translate from "../../../../Utils/Translate";
import F from "../../../../Utils/Functions";
import axios from "axios";
import useDocumentTitle from "../../../../Utils/DocumentTitle";
import {useParams} from "react-router-dom";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const parishes = [
    {"id": "Kingston", "value": "Kingston"},
    {"id": "St. Andrew", "value": "St. Andrew"},
    {"id": "St. Catherine", "value": "St. Catherine"},
    {"id": "St. Mary", "value": "St. Mary"},
    {"id": "Portland", "value": "Portland"},
    {"id": "St. Thomas", "value": "St. Thomas"},
    {"id": "Clarendon", "value": "Clarendon"},
    {"id": "St. Ann", "value": "St. Ann"},
    {"id": "Manchester", "value": "Manchester"},
    {"id": "Trelawny", "value": "Trelawny"},
    {"id": "St. Elizabeth", "value": "St. Elizabeth"},
    {"id": "St. James", "value": "St. James"},
    {"id": "Hanover", "value": "Hanover"},
    {"id": "Westmoreland", "value": "Westmoreland"}
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function ExpressOnboarding(props) {

    const [loading, setLoading] = useState(false);

    const [newAccount, setNewAccount] = useState({email: '', phoneNumber: '', firstName: '', lastName: ''});
    const [onboardingResult, setOnboardingResult] = useState({});

    const handleChange = e => {
        setNewAccount({...newAccount, [e.target.name]: e.target.value});
    }

    useEffect(() => {

    });

    const [currentPage, setCurrentPage] = useState("");

    const handleEnterKeyRegister = e => {
        if ( e.key === 'Enter' ) {
            submitRegistration();
        }
    }

    const submitRegistration = async channel => {
        setLoading(true);
        let body = {...newAccount, channel};
        const response = await Http.request({uri: '/express/onboarding/register', body});
        setLoading(false);
        if (response.notification) {
            Notifier(response.notification);
        }
        console.log(response.body)
        if ( response.result === 'success' ) {
            setOnboardingResult(response.body.onboardingResult);
            setCurrentPage("finish");
        }
    }

    const [deliveryAddress, setDeliveryAddress] = useState({addressParish: localStorage.getItem('lastParishValue') || ''});

    const saveDeliveryAddress = async () => {
        setLoading(true);
        let body = {...deliveryAddress, customerId: onboardingResult.customerId};
        if ( deliveryAddress.addressParish ) localStorage.setItem('lastParishValue', deliveryAddress.addressParish);
        const response = await Http.request({uri: `/express/onboarding/save-address`, body});
        setLoading(false);
        if (response.notification) {
            Notifier(response.notification);
        }
        if ( response.result === 'success' ) {
            clearOnboarding();
        }
    }

    const clearOnboarding = () => {
        setCurrentPage('start');
        localStorage.removeItem('state');
        setNewAccount({firstName: '', lastName: '', email: '', phoneNumber: ''});
        setDeliveryAddress({addressLine1: '', addressLine2: '', addressTown: '', addressParish: localStorage.getItem('lastParishValue')});
        setOnboardingResult({boxNumber: '', customerId: '', fullName: '', channel: ''});
    }

    const setAddressParish = addressParish => {
        setDeliveryAddress({...deliveryAddress, addressParish});
    }

    const handleChangeDeliveryAddress = e => setDeliveryAddress({...deliveryAddress, [e.target.name]: e.target.value});

    return (
      <>
        <div className="min-h-screen flex flex-col items-center justify-center -m-10">
            <h1 className={'page-sub-title text-center font-bold '}>
                <Translate text={'Express Onboarding'} />
            </h1>
          <div className="max-w-md mt-5">
            <Container>
                <div className={"p-5 pt-0"}>
                    {(!currentPage || currentPage === '' || currentPage === 'intro') && <>
                        <div className={'text-red-700 text-center'}>
                            <p>This is a special tool provisioned so we can assist NEW members with onboarding (registration). It still respects the rules of the app, e.g. validating against duplicate accounts.</p>
                            <br />
                            <p>
                                <strong>
                                    USE WITH CAUTION!<br />
                                    RESPECT PEOPLE'S PRIVACY!!<br/>
                                    DO NOT SHARE YOUR CREDENTIALS!!!<br />
                                </strong>
                            </p>
                            <br />
                            <br />
                            <button
                                type="submit"
                                className="w-full rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                onClick={() => setCurrentPage('start')}
                            >
                                <Translate text={"Let me In!"} />
                            </button>
                        </div>
                    </>}
                    {currentPage === 'start' &&
                        <div onKeyPress={e => handleEnterKeyRegister(e)} className={''}>
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 form-field-label">
                                <Translate text={"Name"} />
                            </label>
                            <div className="">
                                <div>
                                    <div className="">
                                        <Input
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            className={"form-field-input"}
                                            value={newAccount.firstName}
                                            required={true}
                                            autoComplete="firstName"
                                            onChange={e => handleChange(e)}
                                        />
                                    </div>
                                    <p className="font-small text-gray-600 text-sm">
                                        <small><Translate text={"First name"} /></small>
                                    </p>
                                </div>
                                <div>
                                    <div className="">
                                        <Input
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            className={"form-field-input"}
                                            value={newAccount.lastName}
                                            required={true}
                                            autoComplete="lastName"
                                            onChange={e => handleChange(e)}
                                        />
                                    </div>
                                    <p className="font-small text-gray-600 text-sm">
                                        <small><Translate text={"Last name"} /></small>
                                    </p>
                                </div>
                            </div>

                            <br />

                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 form-field-label">
                                <Translate text={"Email Address"} />
                            </label>
                            <div className="">
                                <div>
                                    <div className="mt-1">
                                        <Input
                                            id="email"
                                            name="email"
                                            type="text"
                                            className={"form-field-input"}
                                            value={newAccount.email}
                                            onChange={e => handleChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br />

                            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 form-field-label">
                                <Translate text={"Phone Number"} />
                            </label>
                            <div className="">
                                <div>
                                    <div className="mt-1">
                                        <Input
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            type="text"
                                            className={"form-field-input"}
                                            value={newAccount.phoneNumber}
                                            onChange={e => handleChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <br />

                            <div>
                                {!loading &&
                                    <>
                                        Register with &nbsp;
                                        <button
                                            type="submit"
                                            className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => submitRegistration('email')}
                                        >
                                            <Translate text={"Email"}/>
                                        </button>
                                        &nbsp;
                                        <button
                                            type="submit"
                                            className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => submitRegistration('phone')}
                                        >
                                            <Translate text={"SMS"}/>
                                        </button>
                                        <br/><br/>
                                        <button
                                            type="submit"
                                            className="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-grey-800 focus:outline-none focus:ring-2 focus:ring-grey-500 focus:ring-offset-2"
                                            onClick={() => clearOnboarding()}
                                        >
                                            <Translate text={"Clear & Start Over"}/>
                                        </button>
                                    </>
                                }
                                {loading &&
                                    <>
                                        <em><span className={"text-indigo-600"}>Please wait...</span></em>
                                    </>
                                }
                            </div>

                        </div>
                    }
                    {currentPage === 'finish' && <>
                        <h1 className={'text-green-700'}>NEW BOX NUMBER:<br />
                            <strong className={'text-5xl'}>{onboardingResult.boxNumber}</strong>
                            <button
                                type="submit"
                                className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 float-right"
                                onClick={() => clearOnboarding()}
                            >
                                <Translate text={"Next"} />
                            </button>
                        </h1>
                        <div className={"bg-green-300 p-5 text-sm"}><strong>{onboardingResult.fullName}</strong> registered by <strong>{onboardingResult.channel}</strong> using {[onboardingResult.emailAddress, onboardingResult.phoneNumber].filter(i => `${i}`.trim()).join(' and ')}</div>
                        <br />
                        <div className={''}>

                            <h2 className={'text-xl font-bold'}>
                                <Translate text={'Delivery Address'} />
                            </h2>

                            <div
                                className="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-2">

                                <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"First line of address"} />:
                                </label>
                                <div className="">
                                    <Input
                                        id="addressLine1"
                                        name="addressLine1"
                                        type="text"
                                        className={"form-field-input"}
                                        value={deliveryAddress.addressLine1}
                                        required={true}
                                        autoComplete="addressLine1"
                                        onChange={e => handleChangeDeliveryAddress(e)}
                                    />
                                </div>
                                <label htmlFor="addressLine1" className="text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Second line of address"} />:
                                </label>
                                <div className="">
                                    <Input
                                        id="addressLine2"
                                        name="addressLine2"
                                        type="text"
                                        className={"form-field-input"}
                                        value={deliveryAddress.addressLine2}
                                        required={false}
                                        autoComplete="addressLine2"
                                        onChange={e => handleChangeDeliveryAddress(e)}
                                    />
                                </div>
                                <label htmlFor="addressTown" className="text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Town or district"} />:
                                </label>
                                <div className="">
                                    <Input
                                        id="addressTown"
                                        name="addressTown"
                                        type="text"
                                        className={"form-field-input"}
                                        value={deliveryAddress.addressTown}
                                        required={true}
                                        autoComplete="addressTown"
                                        onChange={e => handleChangeDeliveryAddress(e)}
                                    />
                                </div>
                                <label htmlFor="addressLine1" className="text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Parish"} />:
                                </label>
                                <div className="">
                                    <Listbox value={deliveryAddress.addressParish} onChange={setAddressParish}>
                                        {({ open }) => (
                                            <>
                                                <div className="relative mt-1">
                                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                                        <span className="block truncate">{deliveryAddress.addressParish}</span>
                                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                                                    </Listbox.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {parishes.map((parish) => (
                                                                <Listbox.Option
                                                                    key={parish.value}
                                                                    className={({ active }) =>
                                                                        classNames(
                                                                            active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                        )
                                                                    }
                                                                    value={parish.value}
                                                                >
                                                                    {({ selected, active }) => (
                                                                        <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {parish.value}
                                    </span>

                                                                            {selected ? (
                                                                                <span
                                                                                    className={classNames(
                                                                                        active ? 'text-white' : 'text-blue-600',
                                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                    )}
                                                                                >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            ))}
                                                        </Listbox.Options>
                                                    </Transition>
                                                </div>
                                            </>
                                        )}
                                    </Listbox>
                                </div>
                                <br />

                                <div>
                                    {!loading &&
                                        <>
                                            <button
                                                type="submit"
                                                className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                onClick={() => saveDeliveryAddress()}
                                            >
                                                <Translate text={"Save Address"}/>
                                            </button>
                                            &nbsp;
                                            <button
                                                type="submit"
                                                className="rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                                onClick={() => clearOnboarding()}
                                            >
                                                <Translate text={"Cancel"}/>
                                            </button>
                                        </>
                                    }
                                    {loading &&
                                        <>
                                            <em><span className={"text-indigo-600"}>Please wait...</span></em>
                                        </>
                                    }
                                </div>

                            </div>

                        </div>
                    </>}
                </div>
            </Container>
          </div>
        </div>
      </>
  );
}