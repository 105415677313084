import { Fragment, useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import { CurrencyText, DateText, Input, Paging, RelativeDate, ShortenInText } from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon, CheckIcon,
  CreditCardIcon,
  CurrencyDollarIcon, EyeIcon, FlagIcon,
  InformationCircleIcon, MagnifyingGlassIcon, PlusCircleIcon,
  ScaleIcon, TrashIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import axios from "axios";
import { Helmet } from "react-helmet";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import Swal from'sweetalert2';

export default function BrowseInvoices(props) {

  useDocumentTitle('Invoices');

  const [packages, setPackages] = useState([]);
  const [invoiceMode, setInvoiceMode] = useState(false);
  const [invoiceEditTarget, setInvoiceEditTarget] = useState(null);
  const [pagination, setPagination] = useState({});
  const [query, setQuery] = useState({});
  const [keywords, setKeywords] = useState('');

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async (p, q) => {
    // console.log('paging fetch', pagination)
    let body = { paging: p || pagination, keywords, query: q || query };
    const response = await Http.request({ uri: `/invoices/browse`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
    setPackages(response.body.packages);
    setPagination(response.body.pagination);
  }

  const statusDefinitions = window.getConfig('STATUS_DEFINITIONS') || {};
  const getStatusStyle = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.style || `${status}`;
  }

  const getStatusName = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.label || `${status}`;
  }

  const onChangeKeywords = e => setKeywords(e.target.value);

  const updatePaging = p => {
    // console.log('paging call', p)
    setPagination(p);
  }

  const paging = <Paging
    pagination={pagination}
    pagingUpdate={updatePaging}
    pagingAction={fetchPackages}
    searchUpdate={onChangeKeywords}
    keywords={keywords}
  />

  const filters = [
    { label: 'Packages with Invoice', value: 'received' },
    { label: 'Packages without Invoice', value: 'required' },
    { label: 'Packages awaiting Invoice', value: 'requested' },
    { label: 'All', value: 'all' },
  ];

  const [activeFilter, setActiveFilter] = useState('received');
  const toggleActiveFilter = filter => {
    let q = { ...query };
    if (activeFilter === filter || !filter) {
      setActiveFilter('');
      if (q.invoiceStatus) delete q.invoiceStatus;
      fetchPackages(pagination, q);
    } else {
      setActiveFilter(filter);
      q.invoiceStatus = filter;
      fetchPackages(pagination, q);
    }
  };

  const handleChange = e => setQuery({ ...query, [e.target.name]: e.target.value });

  const handleEnterKey = e => e.key === 'Enter' ? fetchPackages(pagination, query) : null;

  const requestInvoice = async (packageId) => {
    // console.log('paging fetch', pagination)
    let body = { packageId };
    const response = await Http.request({ uri: `/invoices/request`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
    setActiveFilter('requested');
    fetchPackages(pagination, { invoiceStatus: 'requested' });
  }

  const viewInvoice = async (invoiceFileKey) => {
    let fileUrl = `${window.getServiceEndpoint()}/public/fs/bucket/${invoiceFileKey}?${window.getQueryStringHeaders()}`;
    window.open(fileUrl);
  }

  const filterBar = <>
    {filters.map((filter, filterKey) => (
      <Fragment key={filterKey}>
        <span className={`clickable inline-flex items-center rounded-full px-4 py-1 text-sm font-medium ${activeFilter !== filter.value ? 'text-black bg-blue-200' : 'text-white bg-indigo-400'}`} onClick={() => toggleActiveFilter(filter.value)}>
          <Translate text={filter.label} />
        </span> &nbsp;&nbsp;
      </Fragment>
    ))}
  </>;

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Invoice Management"}></Header>
          <br />
          <Container>
            <div className="">
              <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    {filterBar}
                    <br /><br />
                    <div className={'bg-gray-200 p-3 border-gray-500'}>
                      <div className="grid lg:grid-cols-5 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6" onKeyDown={(e) => handleEnterKey(e)}>

                        <div className={'col-span-2'}>
                          <label htmlFor="trackingNumber"
                            className="block text-sm font-medium text-gray-700 form-field-label">
                            <Translate text={"Tracking number"} />
                          </label>
                          <div className="mt-1">
                            <Input
                              id="trackingNumber"
                              name="trackingNumber"
                              type="text"
                              className={"form-field-input"}
                              value={query.trackingNumber}
                              required={true}
                              onChange={e => handleChange(e)}
                            />
                          </div>
                        </div>

                        <div className={'col-span-2'}>
                          <label htmlFor="handlerId"
                            className="block text-sm font-medium text-gray-700 form-field-label">
                            <Translate text={"Package ID"} />
                          </label>
                          <div className="mt-1">
                            <Input
                              id="handlerId"
                              name="handlerId"
                              type="text"
                              className={"form-field-input"}
                              value={query.handlerId}
                              required={true}
                              onChange={e => handleChange(e)}
                            />
                          </div>
                        </div>

                        <div className={'col-span-1'}>
                          <label>&nbsp;</label>
                          <div className="mt-1">
                            <button className={'file-chooser-label p-1 text-sm bg-white'}
                              onClick={() => fetchPackages(pagination, query)}><MagnifyingGlassIcon
                                className={'inline'} height={"16"} /> <Translate
                                text={"Search"} /></button>
                          </div>
                        </div>

                      </div>
                    </div>
                    <br />
                    <hr />
                    <div className="mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-white divide-y divide-x divide-gray-200">
                          <tr className={'divide-x divide-gray-300'}>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"Handler#"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Member"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Title"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Tracking"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Weight"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Status"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Date"} />
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">&nbsp;</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-x divide-gray-200">
                          {packages.map((packageInfo, packageIndex) => (
                            <tr key={packageInfo.packageNumber} className={`divide-x divide-gray-300 ${packageIndex % 2 === 0 ? undefined : 'bg-white'}`}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.handlerId}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                                {packageInfo.customerId && <a href={`/members/${packageInfo.customerId}/manage`}>
                                  {packageInfo.boxNumber} - {(packageInfo.meta?.memberName || '').split(' ')[0]}
                                </a>}
                                {!packageInfo.customerId && <>{packageInfo.meta?.memberName}</>}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><ShortenInText text={packageInfo.title} maxLength={24} /></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><ShortenInText text={packageInfo.trackingNumber} />{packageInfo.trackingNumber.indexOf('NO-TRACKING') < 0 && <>&nbsp;<a href={`https://parcelsapp.com/en/tracking/${packageInfo.trackingNumber}`} target={"_blank"} title={"Track this Package"}><EyeIcon height={"16"} className={"inline"} /></a></>}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.weight} {packageInfo.weight !== 1 ? 'lbs' : 'lb'} </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{packageInfo.movementStatus}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><DateText value={packageInfo.createdAt} /></td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                {packageInfo.invoiceStatus !== 'received' && packageInfo.invoiceStatus !== 'requested' &&
                                  <>
                                    {!packageInfo.customerId && <span className={'text-red-800'} title={"This package has not been assigned to an AirShip member as yet."}>Unassigned <InformationCircleIcon height={"16"} className={"inline"} /> </span>}
                                    {packageInfo.customerId &&
                                      <button className={'file-chooser-label-base bg-blue-200'}
                                        onClick={() => {
                                          if (window.confirm(`We'll send ${packageInfo.meta?.memberName || ""} a request to submit an invoice. Please confirm.`)) {
                                            requestInvoice(packageInfo._id);
                                          }
                                        }}><FlagIcon
                                          className={'inline'} height={"16"} /> <Translate
                                          text={"Request"} /></button>
                                    }
                                  </>
                                }
                                {packageInfo.invoiceStatus === 'requested' && <span className={'text-blue-800'}>Invoice Requested</span>}
                                {packageInfo.invoiceStatus === 'received' && <>
                                  <button className={'file-chooser-label-base bg-green-200'}
                                    onClick={() => viewInvoice(packageInfo.invoiceFileKey)}><EyeIcon
                                      className={'inline'} height={"16"} /> <Translate
                                      text={"View"} /></button>

                                  &nbsp; <button onClick={async (e) => {
                                    e.preventDefault();
                                    let confirm = await Swal.fire({
                                      title: 'Share Invoice',
                                      text: 'Are you sure you want to share this invoice with the carrier?',
                                      icon: 'warning',
                                      showCancelButton: true,
                                      confirmButtonText: 'Yes, share it!',
                                      cancelButtonText: 'No, cancel!',
                                      reverseButtons: true
                                    });
                                    if (!confirm.isConfirmed) return;
                                    let body = { packageId: packageInfo._id };
                                    const response = await Http.request({ uri: `/invoices/share`, body });
                                    if (response.notification) {
                                      Notifier(response.notification);
                                    }
                                  }}
                                  ><Translate text={"Share"} /></button>
                                </>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

    </>
  );
}