import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Browse from "./pages/Browse";

export default function Batches() {
    return (
      <main>
        <Routes>
            <Route path="*" element={<Browse />} />
        </Routes>
      </main>
    );
  }