import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, Input, Paging, RelativeDate} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon, CheckIcon, ChevronUpDownIcon,
  CreditCardIcon,
  CurrencyDollarIcon, GlobeAltIcon,
  InformationCircleIcon, PaperAirplaneIcon, PencilIcon, PlayCircleIcon, PlusCircleIcon,
  ScaleIcon, StopCircleIcon, TruckIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import axios from "axios";
import {Helmet} from "react-helmet";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import {Listbox, Transition} from "@headlessui/react";
import F from "../../../../../Utils/Functions";

export default function Browse(props) {

  useDocumentTitle('Batch Processes');

  const [batches, setBatches] = useState([]);
  const [pagination, setPagination] = useState({});
  const [keywords, setKeywords] = useState('');
  const [view, setView] = useState('');
  const [batchId, setBatchId] = useState(null);

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = async p => {
    // console.log('paging fetch', pagination)
    let body = {paging: p || pagination, keywords};
    const response = await Http.request({uri: `/batches/browse`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setBatches(response.body.batches);
    setPagination(response.body.pagination);
  }

  const getBatch = async id => {
    setBatchId(id);
    let body = {};
    const response = await Http.request({uri: `/batches/${id}/get`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setBatchInfo(response.body);
    setView('editBatch');
  }

  const onChangeKeywords = e => setKeywords(e.target.value);

  const updatePaging = p => {
    console.log('paging call', p)
    setPagination(p);
  }

  const batchStatuses = [
    {"label": "(empty)", "value": ""},
    {"label": "Active", "value": "active"},
    {"label": "Paused", "value": "paused"},
    {"label": "Processing", "value": "processing"},
    {"label": "Cancelled", "value": "cancelled"},
    {"label": "Expired", "value": "expired"},
  ];

  const batchTypes = [
    {"label": "(empty)", "value": ""},
    {"label": "Loading Batch", "value": "loading"},
    {"label": "Clearance Batch", "value": "clearance"}
  ];

  const getStatusName = status => {
    return batchStatuses.filter(item => item.value === status)[0]?.label;
  }

  const getBatchType = status => {
    return batchTypes.filter(item => item.value === status)[0]?.label;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const [batchInfo, setBatchInfo] = useState({type: ''});
  const handleEnterKey = e => {
    if ( e.key === 'Enter' ) {
      submitBatch();
    }
  }
  const handleChange = e => setBatchInfo({...batchInfo, [e.target.name]: e.target.value});
  const submitBatch = async () => {
    let body = batchInfo;
    const response = await Http.request({uri: `/batches/create`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchBatches();
      setView('');
      setBatchInfo({type: ''});
    }
  };
  const updateBatch = async () => {
    let body = batchInfo;
    const response = await Http.request({uri: `/batches/${batchId}/update`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchBatches();
      setView('');
      setBatchInfo({type: ''});
    }
  };
  const setBatchType = type => setBatchInfo({...batchInfo, type});

  const paging = <Paging
      pagination={pagination}
      pagingUpdate={updatePaging}
      pagingAction={fetchBatches}
      searchUpdate={onChangeKeywords}
      keywords={keywords}
  />

  const setBatchAction = async (action, id) => {
    let body = {action, id};
    const response = await Http.request({uri: `/batches/${id || batchId}/set-action`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchBatches();
      setView('');
      setBatchInfo({type: ''});
    }
  };

  const updateBatchStatus = async (status) => {
    let body = {status};
    const response = await Http.request({uri: `/batches/${batchId}/set-status`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchBatches();
      setView('');
      setBatchInfo({type: ''});
    }
  };

  const updateBatchType = async (type, id) => {
    let body = {type, id};
    const response = await Http.request({uri: `/batches/${id || batchId}/set-type`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchBatches();
      setView('');
      setBatchInfo({type: ''});
    }
  };

  const deleteBatch = async () => {
    let body = {};
    const response = await Http.request({uri: `/batches/${batchId}/delete`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchBatches();
      setView('');
      setBatchInfo({type: ''});
    }
  };

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Batch Processes"}></Header>
            <br />
            <div className={'text-center'}>
              <span className={''}>
                  <a className={'clickable rounded-md border border-transparent bg-green-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 force-text-white'} onClick={() => setView('createBatch')}><PlusCircleIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"New Batch"} /></a>
              </span>
            </div>
            <br />
            <Container>
              <div className="">
                <div className="flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      {['createBatch', 'editBatch'].includes(view) &&
                          <div>

                            {view === 'editBatch' &&
                                <>
                                  <div className={'text-center'}>
                                    <strong><Translate text={"Batch actions"}/>:&nbsp;&nbsp;</strong>
                                    {!batchInfo.running && batchInfo.status === 'active' && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => setBatchAction('run')}
                                      >
                                        <Translate text={"Run Now"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {!['cancelled', 'paused'].includes(batchInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => updateBatchStatus('paused')}
                                      >
                                        <Translate text={"Pause"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {['paused'].includes(batchInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => updateBatchStatus('active')}
                                      >
                                        <Translate text={"Resume"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {!['cancelled'].includes(batchInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => updateBatchStatus('cancelled')}
                                      >
                                        <Translate text={"Cancel"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {['cancelled'].includes(batchInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => updateBatchStatus('active')}
                                      >
                                        <Translate text={"Restore"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => deleteBatch()}
                                      >
                                        <Translate text={"Delete"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>
                                  </div>
                                  <br/>
                                </>
                            }
                            <div className={'bg-blue-100 px-10 py-10 shadow rounded-xl'} onKeyPress={e => handleEnterKey(e)}>
                              <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                <div className={'col-span-1'}>
                                  <label htmlFor="name"
                                         className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Name"}/>
                                  </label>
                                  <div className="mt-1">
                                    <Input
                                        id="name"
                                        name="name"
                                        type="text"
                                        className={"form-field-input"}
                                        value={batchInfo.name}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />
                                  </div>
                                </div>

                                <div className={'col-span-1'}>
                                  <label htmlFor="type" className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Type"} />
                                  </label>
                                  <div className="mt-1">
                                    <Listbox value={batchInfo.type} onChange={setBatchType}>
                                      {({ open }) => (
                                          <>
                                            <div className="relative mt-1">
                                              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                                <span className="block truncate">{F.getListValue(batchInfo.type, batchTypes)}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                                              </Listbox.Button>

                                              <Transition
                                                  show={open}
                                                  as={Fragment}
                                                  leave="transition ease-in duration-100"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                              >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                  {batchTypes.map((type) => (
                                                      <Listbox.Option
                                                          key={type.value}
                                                          className={({ active }) =>
                                                              classNames(
                                                                  active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                                              )
                                                          }
                                                          value={type.value}
                                                      >
                                                        {({ selected, active }) => (
                                                            <>
                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {F.getListValue(type.value, batchTypes)}
                                      </span>

                                                              {selected ? (
                                                                  <span
                                                                      className={classNames(
                                                                          active ? 'text-white' : 'text-blue-600',
                                                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                      )}
                                                                  >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                                              ) : null}
                                                            </>
                                                        )}
                                                      </Listbox.Option>
                                                  ))}
                                                </Listbox.Options>
                                              </Transition>
                                            </div>
                                          </>
                                      )}
                                    </Listbox>
                                  </div>
                                </div>

                              </div>

                              <br />

                              <div className="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                <div className={'col-span-1'}>
                                  <label htmlFor="url"
                                         className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"URL"}/>
                                  </label>
                                  <div className="mt-1">
                                    <Input
                                        id="url"
                                        name="url"
                                        type="text"
                                        className={"form-field-input"}
                                        value={batchInfo.url}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />
                                  </div>
                                </div>

                              </div>

                              <br/>

                              <div>
                                <div>
                                  {view === 'createBatch' && <button
                                      type="submit"
                                      className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      onClick={() => submitBatch()}
                                  >
                                    <Translate text={"Create Batch"}/>
                                  </button>}
                                  {view === 'editBatch' && <button
                                      type="submit"
                                      className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      onClick={() => updateBatch()}
                                  >
                                    <Translate text={"Update Batch"}/>
                                  </button>}
                                  &nbsp; &nbsp; <button
                                    type="reset"
                                    className="rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    onClick={() => {setView(''); setBatchInfo({type: ''})}}
                                >
                                  <Translate text={"Cancel"}/>
                                </button>
                              </div>
                            </div>
                            <br />
                            <br />
                            </div>

                          </div>
                      }
                      {paging}
                      <div className="mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-white divide-y divide-x divide-gray-200">
                          <tr className={'divide-x divide-gray-300'}>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              #
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"Name"} />
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"Sheet ID"} />
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"Type"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Created"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Last Run"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Status"} />
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">&nbsp;</span>
                            </th>
                          </tr>
                          </thead>
                          <tbody className="divide-y divide-x divide-gray-200">
                          {batches.map((batch, b) => (
                              <tr key={batch.batchNumber} className={`divide-x divide-gray-300 ${b % 2 === 0 ? undefined : 'bg-white'}`}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {batch.batchNumber}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {batch.name}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {batch.sheetId?.slice(batch.sheetId?.length - 6, batch.sheetId?.length).toUpperCase()}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {getBatchType(batch.type)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><DateText value={batch.createdAt} /></td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><RelativeDate value={batch.lastRunAt} /></td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{getStatusName(batch.status)}{' '}{batch.running &&
                                    <span style={{color: '#32d62f'}} title={"Running Now"}>●</span>}</td>
                                <td className="relative whitespace-nowrap py-4 pl-1 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <a className={'clickable'} onClick={() => getBatch(batch._id)}>
                                    <PencilIcon height={"16"} className={'inline'} /><span className="sr-only">, <Translate text={"Edit"} /></span>
                                  </a>
                                  {!batch.running && batch.status === 'active' &&
                                  <>
                                    &nbsp;
                                    <a className={'clickable'} onClick={() => setBatchAction('run', batch._id)}>
                                      <PlayCircleIcon height={"16"} className={'inline'} /><span className="sr-only">, <Translate text={"Run"} /></span>
                                    </a>
                                    &nbsp;
                                  </>
                                  }
                                  {batch.running &&
                                      <>
                                        &nbsp;
                                        <a className={'clickable'} onClick={() => setBatchAction('stop', batch._id)}>
                                          <StopCircleIcon height={"16"} className={'inline'} /><span className="sr-only">, <Translate text={"Stop"} /></span>
                                        </a>
                                        &nbsp;
                                      </>
                                  }
                                  {batch.type === 'loading' &&
                                      <>
                                        &nbsp;
                                        <a className={'clickable'} onClick={() => updateBatchType('clearance', batch._id)}>
                                          <PaperAirplaneIcon height={"16"} className={'inline'} /><span className="sr-only">, <Translate text={"Clearance"} /></span>
                                        </a>
                                        &nbsp;
                                      </>
                                  }
                                  {batch.type === 'clearance' &&
                                      <>
                                        &nbsp;
                                        <a className={'clickable'} onClick={() => updateBatchType('loading', batch._id)}>
                                          <TruckIcon height={"16"} className={'inline'} /><span className="sr-only">, <Translate text={"Loading"} /></span>
                                        </a>
                                        &nbsp;
                                      </>
                                  }
                                  &nbsp;
                                  <a href={batch.url} target={'_blank'}>
                                    <GlobeAltIcon height={"16"} className={'inline'} /><span className="sr-only">, <Translate text={"Loading"} /></span>
                                  </a>
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                      <br />
                      {pagination.count > 10 && <>{paging}</>}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>

      </>
  );
}