import { Fragment, useState } from "react";
import Http from "../../Utils/Http";
import Notifier from "../../Utils/Notifier";

import Template, { Input } from "../../Utils/Template";
import Header from "../../Components/Header";
import Container from "../../Components/Container";
import Translate from "../../Utils/Translate";
import useDocumentTitle from "../../Utils/DocumentTitle";

export default function Login() {

  useDocumentTitle('Login');

  const [credentials, setCredentials] = useState({});

  const handleChange = e =>{
    setCredentials({...credentials, [e.target.name]: e.target.value});
  }

  const submitLogin = async () => {
    let body = {...credentials};
    const response = await Http.request({uri: '/auth/login', body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      localStorage.setItem('access_token', response.body.access_token);
      localStorage.setItem('emailAddress', response.body.emailAddress);
      localStorage.setItem('officerName', response.body.officerName);
      let targetPage = localStorage.getItem('targetPage') || response.body.homeScreen || '/';
      if ( targetPage ) {
        localStorage.removeItem('targetPage');
        if ( !targetPage || targetPage === '' || targetPage === '/' || targetPage.indexOf('/auth') > -1 ) targetPage = '/packages';
      } else {
        targetPage = '/';
      }
      console.log(targetPage)
      window.setTimeout(() => window.location.replace(targetPage), 1000);
    }
  }

  const handleEnterKey = e => {
    if ( e.key === 'Enter' ) {
      submitLogin();
    }
  }

    return (
      <Fragment>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Log In"}></Header>
            <br />
            <Container size={"narrow"}>
              <div className="">
                {localStorage.getItem('officerName') &&
                    <>
                      <div className={'bg-red-300 p-10 rounded-lg'}>
                        <Translate text={"You might have been logged out for security reasons. Please log in again."} />
                      </div>
                      <br />
                    </>
                }

                <br />
                <hr />
                <br />

                <div className="mt-8">
                  <div className="mt-6" onKeyPress={e => handleEnterKey(e)}>
                    <div className="space-y-6">
                      <div>
                        <label htmlFor="emailAddress" className="block text-sm font-medium text-gray-700 form-field-label">
                          <Translate text={"What's your company email?"} />
                        </label>
                        <div className="mt-1">
                        <Input
                          id="emailAddress"
                          name="emailAddress"
                          className={"form-field-input"}
                          type="text"
                          value={credentials.emailAddress}
                          autoComplete="emailAddress"
                          onChange={e => handleChange(e)}
                        />
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 form-field-label">
                          <Translate text={"What's your password?"} />
                        </label>
                        <div className="mt-1">
                        <Input
                          id="password"
                          name="password"
                          className={"form-field-input"}
                          type="password"
                          value={credentials.password}
                          onChange={e => handleChange(e)}
                        />
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 primary-btn"
                          onClick={() => submitLogin()}
                        >
                          <Translate text={"Log In"} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Fragment>
    );
  }