import { Link } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Auth from "./routes/Auth";
import Main from "./routes/Main";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Packages from "./routes/Main/modules/Packages";

import './styles/styles.css';

import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Http from "./Utils/Http";
import Notifier from "./Utils/Notifier";

const user = {
  /*name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    '/assets/images/user-128.png',*/
}
const navigationGuest = [
];

const navigationAuthUser = [
  // { name: `Home`, href: '/', current: false, type: 'textLogo'},
  { name: 'Packages', href: '/packages', current: false },
  { name: 'Invoices', href: '/invoices', current: false },
  { name: 'Members', href: '/members', current: false },
  // { name: 'Users', href: '/users', current: false },
  { name: 'Payments', href: '/payments', current: false },
  { name: 'Batch Processes', href: '/batches', current: false },
  { name: 'Delivery', href: '/operations/delivery', current: false },
  { name: 'Express Onboarding', href: '/express/onboarding', current: false },
  { name: 'Setup', href: '/errors/authwall', current: false },
];

const userNavigationAuthUser = [
  { name: 'Personal Settings', href: '/settings/account' },
  { name: 'Change Password', href: '/settings/password' },
  { name: 'Log Out', href: '/auth/logout' },
];

const userNavigationGuest = [
];

const textLogo = <><img
    className="h-4"
    src={'/assets/images/airship-logo-text-white.png'}
    alt="AirBox Couriers"
/></>;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

let logoImage = ``;

if ( true ) {
  logoImage = '/assets/images/airship-logo-500.png'
};

export default function App() {

  const [navigation, setNavigation] = useState('');

  useEffect( () => {
    window.setConfig();
    if ( !localStorage.HOST_CONFIG ) {
      if ( window.location.href.indexOf('firstRun') >= 0 ) {
        setConfigError(true);
      } else {
        setTimeout(() => window.location.assign(window.location.href + '?firstRun=true'), 1000);
      }
    }
    if ( localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'invalid_client' ) {
      setNavigation('AUTHED');
    }
    if ( window.location.pathname.indexOf('/auth') !== 0 ) Http.request({uri: '/pulse', body: {}});
  }, []);

  const [configError, setConfigError] = useState(false);

  const oldMenu = false;
  const newMenu = true;

  return (
    <>
    {configError && <>
      <h1>Error</h1>
      <p>We encountered an error while attempting to load the app. Please <a href="/?firstRun=true"><strong>try again</strong></a>. If this continues to happen, please contact us.</p>
    </>
    }
    {!configError &&
      <div className="min-h-full pg-bg-img">
        {newMenu && <Disclosure as="nav" className="bg-blue-800 shadow">
          {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <a href={"/"}><img
                            className="h-8 w-8"
                            src={logoImage}
                            alt="AirBox Couriers"
                        /></a>
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-5 flex space-x-4">
                          {(navigation === 'AUTHED' ? navigationAuthUser : navigationGuest).map((item) => (
                              <a
                                  key={item.name}
                                  href={item.href}
                                  className={classNames(
                                      item.current
                                          ? 'bg-blue-800 text-white'
                                          : 'text-white hover:bg-blue-700 hover:bg-opacity-75',
                                      'px-3 py-2 rounded-md text-sm font-medium'
                                  )}
                                  aria-current={item.current ? 'page' : undefined}
                              >
                                {item.type !== 'textLogo' ? item.name : textLogo}
                              </a>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-blue-800 text-sm focus:outline-none focus:ring-2 focus:ring-blue-800 focus:ring-offset-2 focus:ring-offset-blue-800 text-white">
                              <span className="sr-only">Open user menu</span>
                              <img className="h-8 w-8 rounded-full" src={'/assets/images/user.png'} alt="" />&nbsp;<strong>{localStorage.getItem('userName')}</strong>{localStorage.getItem('boxNumber') && <>&nbsp;(BOX-{localStorage.getItem('boxNumber')})</>}
                            </Menu.Button>
                          </div>
                          <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-blue-200 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {(navigation === 'AUTHED' ? userNavigationAuthUser : userNavigationGuest).map((item) => (
                                  <Menu.Item key={item.name}>
                                    {({ active }) => (
                                        <a
                                            href={item.href}
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-black'
                                            )}
                                        >
                                          {item.name}
                                        </a>
                                    )}
                                  </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-blue-800 p-2 text-white hover:bg-blue-700 hover:bg-opacity-75 hover:text-blue-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                    {(navigation === 'AUTHED' ? navigationAuthUser : navigationGuest).map((item) => (
                        <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className={classNames(
                                item.current
                                    ? 'bg-bg-blue-800 text-white'
                                    : 'text-white hover:bg-blue-700 hover:bg-opacity-75',
                                'block px-3 py-2 rounded-md text-base font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-white pt-4 pb-3">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={'/assets/images/user.png'} alt="" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">{localStorage.getItem('userName')}</div>
                        <div className="text-sm font-medium text-white">{localStorage.getItem('userEmail')}</div>
                        {localStorage.getItem('boxNumber') && <div className="text-sm font-medium text-white">(BOX-{localStorage.getItem('boxNumber')})</div>}
                      </div>{/*
                      <button
                          type="button"
                          className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-black hover:text-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>*/}
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {(navigation === 'AUTHED' ? userNavigationAuthUser : userNavigationGuest).map((item) => (
                          <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-blue-700 hover:bg-opacity-75"
                          >
                            {item.name}
                          </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
          )}
        </Disclosure>}
          <BrowserRouter>
              <Routes>
                <Route path="auth/*" element={<Auth />} />
                <Route path="*" element={<Main />} />
              </Routes>
          </BrowserRouter>
          <Footer />
      </div>
    }
    </>
  );
}