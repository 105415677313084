import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, Input, Paging, RelativeDate} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon, CheckIcon, ChevronUpDownIcon,
  CreditCardIcon,
  CurrencyDollarIcon, GlobeAltIcon,
  InformationCircleIcon, LockClosedIcon, PaperAirplaneIcon, PencilIcon, PlayCircleIcon, PlusCircleIcon,
  ScaleIcon, StopCircleIcon, TruckIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import axios from "axios";
import {Helmet} from "react-helmet";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import {Listbox, Transition} from "@headlessui/react";
import F from "../../../../../Utils/Functions";

export default function AuthWall(props) {

  return (
      <>
        <div className="flex min-h-full">
          <div class="flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex lg:justify-center lg:w-1/2 lg:px-20 xl:px-24 sm:mt-5 mx-auto">
            <Header title={"Access Denied"}></Header>
            <br />
            <Container>
              <div className="">
                <div className="text-center">
                  <p className={'text-center'}><LockClosedIcon height={"32"} className={"inline"} /> <strong>Don't Panic!</strong></p>
                  <br />
                  <p>You were sent to this screen because you attempted to do something that is not authorised. Our security policy denies by default, and we give animals access ONLY to what they NEED.</p>
                  <br />
                  <p>If you need access to do what you just tried, simply contact an administrator and they will fix you. Don't beg them.</p>
                </div>
              </div>
            </Container>
          </div>
        </div>

      </>
  );
}