import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Packages from "./modules/Packages";
import Login from "../Auth/Login";
import Members from "./modules/Members";
import Batches from "./modules/Batches";
import Payments from "./modules/Payments";
import {useEffect} from "react";
import Operations from "./modules/Operations";
import Errors from "./modules/Errors";
import Invoices from "./modules/Invoices";
import AppRouter from "./modules/Router";
import Express from "./Express";

export default function Main() {

    useEffect(() => {
        if (window.location.href.indexOf('auth') < 0) localStorage.setItem('targetPage', window.location.pathname);
    }, []);

    return (
      <main>
          {(!localStorage.getItem('access_token') || localStorage.getItem('access_token') === 'invalid_client') &&
              <>
                  <Routes>
                      <Route path="*" element={<Login />}/>
                  </Routes>
              </>
          }

          {localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'invalid_client' &&
              <>
                  <Routes>
                      <Route path="errors/*" element={<Errors />}/>
                      <Route path="packages/*" element={<Packages />}/>
                      <Route path="members/*" element={<Members />}/>
                      <Route path="batches/*" element={<Batches />}/>
                      <Route path="payments/*" element={<Payments />}/>
                      <Route path="operations/*" element={<Operations />}/>
                      <Route path="invoices/*" element={<Invoices />}/>
                      <Route path="express/*" element={<Express />}/>
                      <Route path="router/*" element={<AppRouter />}/>
                      <Route path="*" element={<Packages/>}/>
                  </Routes>
              </>
          }
      </main>
    );
  }