import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import BrowseInvoices from "./pages/BrowseInvoices";

export default function Invoices() {
    return (
      <main>
        <Routes>
            <Route path="browse" element={<BrowseInvoices />} />
            <Route path="*" element={<BrowseInvoices />} />
        </Routes>
      </main>
    );
  }