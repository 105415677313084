import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Login from "../../Auth/Login";
import {useEffect} from "react";
import AppRouter from "../modules/Router";
import ExpressOnboarding from "./pages/ExpressOnboarding";

export default function Express() {

    useEffect(() => {
        localStorage.setItem('targetPage', window.location.pathname);
    }, []);

    return (
      <main>
          {(!localStorage.getItem('access_token') || localStorage.getItem('access_token') === 'invalid_client') &&
              <>
                  <Routes>
                      <Route path="*" element={<Login />}/>
                  </Routes>
              </>
          }

          {localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'invalid_client' &&
              <>
                  <Routes>
                      <Route path="onboarding" element={<ExpressOnboarding />}/>
                      <Route path="router/*" element={<AppRouter />}/>
                  </Routes>
              </>
          }
      </main>
    );
  }