import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import {
    ArrowUpTrayIcon,
    DocumentCheckIcon,
    InformationCircleIcon,
    PlayIcon,
    TrashIcon
} from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import {CurrencyText, DateText, Input} from "../../../../../Utils/Template";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Translate from "../../../../../Utils/Translate";
import F from "../../../../../Utils/Functions";
import axios from "axios";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import {useParams} from "react-router-dom";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function Delivery(props) {

    const [result, setResult] = useState(null);

    const handleScan = data => {
        if ( data ) {
            setResult(data);
            if ( !trackingNumber ) setTrackingNumber(data.text);
        }
    }

    const handleError = err => {
        console.error(err);
    }

    const previewStyle = {
        height: 240,
        width: 320,
    };

    const [trackingNumber, setTrackingNumber] = useState('');
    const [packageNumber, setPackageNumber] = useState('');
    const [handlerId, setHandlerId] = useState('');

    const logDelivery = async () => {
        let body = {scannedTrackingNumber: result?.text || '', trackingNumber, packageNumber, handlerId};
        const response = await Http.request({uri: `/operations/log-delivery`, body});
        if (response.notification) {
            Notifier(response.notification);
        }
        if ( response.result === 'success' ) {
            window.location.reload();
        }
    };

    useEffect(() => {

    });

    const [showScanner, setShowScanner] = useState(false);

    return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Delivery Record"}></Header>
            <br />
            <Container>
                <div className="text-center p-10">
                    {showScanner && <>
                        <p><a onClick={() => setShowScanner(false)} className={"clickable"}><Translate text={"Hide Scanner"} /></a></p>
                        <p>
                            <Translate
                                text={"Scan the barcode on the package and log it as delivered. The system will be updated and relevant notifications will be sent out. Ensure it's the barcode with the tracking number. If you can't scan, type the tracking number below."} />
                        </p>
                        <br/>
                        <hr/>
                        <br/>
                        <div className={'flex'}>
                            <BarcodeScannerComponent
                                width={400}
                                height={400}
                                onUpdate={(err, data) => {
                                    if (data) handleScan(data);
                                    else if (err) handleError(err);
                                }}
                            />
                        </div>
                        <br/>
                        <hr/>
                    </>
                    }
                    {!showScanner && <p><a onClick={() => setShowScanner(true)}><Translate text={"Show Scanner"} /></a></p>}
                    <br />
                    <div>
                        {result && <>
                            <Translate text={"Tracking number captured:"} /><br /><strong>{result.text}</strong>
                            <br /><br /> </>
                        }
                        <label htmlFor="firstName"
                               className="block text-sm font-medium text-gray-700 form-field-label">
                            <Translate text={"Type tracking number, handler ID or package number below if you can't scan."}/>
                        </label>
                        <br />
                        <Input
                            id="trackingNumber"
                            name="trackingNumber"
                            type="text"
                            placeHolder={'Tracking number'}
                            className={"form-field-input"}
                            value={trackingNumber}
                            required={true}
                            onChange={e => setTrackingNumber(e.target.value)}
                        />
                        <br />
                        <Input
                            id="handlerId"
                            name="handlerId"
                            type="text"
                            placeHolder={'Handler ID'}
                            className={"form-field-input"}
                            value={handlerId}
                            required={true}
                            onChange={e => setHandlerId(e.target.value)}
                        />
                        <br />
                        <Input
                            id="packageNumber"
                            name="packageNumber"
                            type="text"
                            placeHolder={'Package number'}
                            className={"form-field-input"}
                            value={packageNumber}
                            required={true}
                            onChange={e => setPackageNumber(e.target.value)}
                        />
                        <br />
                        {(trackingNumber || handlerId || packageNumber || result) && <>
                            <div className={'py-2'}>
                                <button className={'file-chooser-label'}
                                        onClick={() => logDelivery()}><CheckIcon
                                    className={'inline'} height={"16"}/> <Translate
                                    text={"Log Delivery"}/></button>
                                &nbsp;&nbsp;
                                <button className={'file-chooser-label'}
                                        onClick={() => {setResult(null); setTrackingNumber(''); setHandlerId(''); setPackageNumber('')}}><TrashIcon
                                    className={'inline'} height={"16"}/> <Translate
                                    text={"Clear"}/></button>
                            </div>
                        </>}
                    </div>
                </div>
            </Container>
          </div>
        </div>
      </>
  );
}