import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, Input, Paging, RelativeDate} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon, CheckIcon, ChevronUpDownIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  InformationCircleIcon, PlusCircleIcon,
  ScaleIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import axios from "axios";
import {Helmet} from "react-helmet";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import {Listbox, Transition} from "@headlessui/react";
import F from "../../../../../Utils/Functions";

import {useParams} from "react-router-dom";

export default function Browse(props) {

  useDocumentTitle('Payments');

  let { targetTransactionId } = useParams();

  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [keywords, setKeywords] = useState('');
  const [view, setView] = useState('');
  const [transactionId, setTransactionId] = useState(null);

  useEffect(() => {
    fetchTransactions();
    console.log({targetTransactionId})
    if ( targetTransactionId ) {
      getTransaction(targetTransactionId);
    }
  }, []);

  const fetchTransactions = async p => {
    // console.log('paging fetch', pagination)
    let body = {paging: p || pagination, keywords};
    const response = await Http.request({uri: `/transactions/browse`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setTransactions(response.body.transactions);
    setPagination(response.body.pagination);
  }

  const getTransaction = async id => {
    setTransactionId(id);
    let body = {};
    const response = await Http.request({uri: `/payments/${id}/get-transaction`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setTransactionInfo(response.body);
    setView('editTransaction');
  }

  const onChangeKeywords = e => setKeywords(e.target.value);

  const updatePaging = p => {
    console.log('paging call', p)
    setPagination(p);
  }

  const transactionStatuses = [
    {"label": "(empty)", "value": ""},
    {"label": "New", "value": "new"},
    {"label": "Completed", "value": "completed"},
    {"label": "Duplicate", "value": "duplicate"},
    {"label": "Failed", "value": "failed"},
    {"label": "Cancelled", "value": "cancelled"},
    {"label": "Pending", "value": "pending"},
  ];

  const transactionChannels = [
    {"label": "(empty)", "value": ""},
    {"label": "Card", "value": "card"},
    {"label": "Cash", "value": "cash"},
    {"label": "Bank Transfer", "value": "transfer"},
    {"label": "Lynk", "value": "lynk"},
    {"label": "Paypal", "value": "paypal"},
    {"label": "System", "value": "system"},
  ];

  const getStatusName = status => {
    return transactionStatuses.filter(item => item.value === status)[0]?.label || status;
  }

  const getTransactionChannel = status => {
    return transactionChannels.filter(item => item.value === status)[0]?.label || status;
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const [transactionInfo, setTransactionInfo] = useState({channel: ''});
  const handleEnterKey = e => {
    if ( e.key === 'Enter' ) {
      submitTransaction();
    }
  }
  const handleChange = e => setTransactionInfo({...transactionInfo, [e.target.name]: e.target.value});
  const submitTransaction = async () => {
    let body = transactionInfo;
    const response = await Http.request({uri: `/payments/create-transaction`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchTransactions();
      setView('');
      setTransactionInfo({type: ''});
    }
  };
  const updateTransaction = async () => {
    let body = transactionInfo;
    const response = await Http.request({uri: `/payments/${transactionId}/update-transaction`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchTransactions();
      setView('');
      setTransactionInfo({type: ''});
    }
  };
  const setTransactionChannel = channel => setTransactionInfo({...transactionInfo, channel});

  const paging = <Paging
      pagination={pagination}
      pagingUpdate={updatePaging}
      pagingAction={fetchTransactions}
      searchUpdate={onChangeKeywords}
      keywords={keywords}
  />

  const updateTransactionStatus = async status => {
    let body = {status};
    const response = await Http.request({uri: `/payments/${transactionId}/set-transaction-status`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchTransactions();
      setView('');
      setTransactionInfo({type: '', channel: ''});
    }
  };

  const deleteTransaction = async () => {
    let body = {};
    const response = await Http.request({uri: `/payments/${transactionId}/delete-transaction`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchTransactions();
      setView('');
      setTransactionInfo({channel: ''});
    }
  };

  const completeTransaction = async () => {
    let body = {};
    const response = await Http.request({uri: `/payments/${transactionId}/complete-transaction`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchTransactions();
      setView('');
      setTransactionInfo({channel: ''});
    }
  };

  const cancelTransaction = async () => {
    let body = {};
    const response = await Http.request({uri: `/payments/${transactionId}/cancel-transaction`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchTransactions();
      setView('');
      setTransactionInfo({channel: ''});
    }
  };

  const reverseTransaction = async () => {
    let body = {};
    const response = await Http.request({uri: `/payments/${transactionId}/reverse-transaction`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      fetchTransactions();
      setView('');
      setTransactionInfo({channel: ''});
    }
  };

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Payments & Transactions"}></Header>
            <br />
            <div className={'text-center'}>
              <span className={''}>
                  <a className={'clickable rounded-md border border-transparent bg-green-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 force-text-white'} onClick={() => setView('createTransaction')}><PlusCircleIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"New Transaction"} /></a>
              </span>
            </div>
            <br />
            <Container>
              <div className="">
                <div className="flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      {['createTransaction', 'editTransaction'].includes(view) &&
                          <div>
                            {view === 'editTransaction' &&
                                <>
                                  <div className={'text-center'}>
                                    <strong><Translate text={"Transaction actions"}/>:&nbsp;&nbsp;</strong>

                                    {['new', 'pending'].includes(transactionInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => cancelTransaction()}
                                      >
                                        <Translate text={"Cancel"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {['completed'].includes(transactionInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => reverseTransaction()}
                                      >
                                        <Translate text={"Reverse"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {['new', 'pending'].includes(transactionInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => completeTransaction()}
                                      >
                                        <Translate text={"Complete"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {['new', 'pending'].includes(transactionInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => updateTransactionStatus('duplicate')}
                                      >
                                        <Translate text={"Mark as Duplicate"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {['new'].includes(transactionInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => updateTransactionStatus('pending')}
                                      >
                                        <Translate text={"Mark as Pending"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {['cancelled', 'pending', 'failed', 'duplicate'].includes(transactionInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => updateTransactionStatus('new')}
                                      >
                                        <Translate text={"Reset"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>}

                                    {['new', 'cancelled', 'pending', 'failed'].includes(transactionInfo.status) && <>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() => deleteTransaction()}
                                      >
                                        <Translate text={"Delete"}/>
                                      </button>
                                      &nbsp; &nbsp;
                                    </>
                                    }
                                  </div>
                                  <br/>
                                </>
                            }
                            <div className={'bg-blue-100 px-10 py-10 shadow rounded-xl'} onKeyPress={e => handleEnterKey(e)}>
                              <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-6">

                                <div className={'col-span-1'}>
                                  <label htmlFor="boxNumber"
                                         className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Box Number"}/>
                                  </label>
                                  <div className="mt-1">
                                    {transactionInfo.type === 'payment' && ['new', 'pending'].includes(transactionInfo.status) && <Input
                                        id="boxNumber"
                                        name="boxNumber"
                                        type="text"
                                        className={"form-field-input"}
                                        value={transactionInfo.boxNumber}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />}
                                    {transactionInfo.type !== 'payment' &&
                                        <>
                                          {transactionInfo.boxNumber}
                                        </>
                                    }
                                  </div>
                                </div>

                                <div className={'col-span-1'}>
                                  <label htmlFor="amount"
                                         className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Amount"}/>
                                  </label>
                                  <div className="mt-1">
                                    {transactionInfo.type === 'payment' && ['new', 'pending'].includes(transactionInfo.status) &&
                                    <Input
                                        id="amount"
                                        name="amount"
                                        type="text"
                                        className={"form-field-input"}
                                        value={transactionInfo.amount}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />}
                                    {transactionInfo.type !== 'payment' &&
                                        <>
                                          <CurrencyText value={transactionInfo.amount} />
                                        </>
                                    }
                                  </div>
                                </div>

                                <div className={'col-span-1'}>
                                  <label className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Type"}/>
                                  </label>
                                  <div className="mt-1">
                                    {transactionInfo.type?.toTitleCase()}
                                  </div>
                                </div>

                                <div className={'col-span-1'}>
                                  <label htmlFor="channel" className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Channel"} />
                                  </label>
                                  <div className="mt-1">
                                    <Listbox value={transactionInfo.channel} onChange={setTransactionChannel}>
                                      {({ open }) => (
                                          <>
                                            <div className="relative mt-1">
                                              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                                <span className="block truncate">{F.getListValue(transactionInfo.channel, transactionChannels)}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                                              </Listbox.Button>

                                              <Transition
                                                  show={open}
                                                  as={Fragment}
                                                  leave="transition ease-in duration-100"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                              >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                  {transactionChannels.map((channel) => (
                                                      <Listbox.Option
                                                          key={channel.value}
                                                          className={({ active }) =>
                                                              classNames(
                                                                  active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                                              )
                                                          }
                                                          value={channel.value}
                                                      >
                                                        {({ selected, active }) => (
                                                            <>
                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {F.getListValue(channel.value, transactionChannels)}
                                      </span>

                                                              {selected ? (
                                                                  <span
                                                                      className={classNames(
                                                                          active ? 'text-white' : 'text-blue-600',
                                                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                      )}
                                                                  >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                                              ) : null}
                                                            </>
                                                        )}
                                                      </Listbox.Option>
                                                  ))}
                                                </Listbox.Options>
                                              </Transition>
                                            </div>
                                          </>
                                      )}
                                    </Listbox>
                                  </div>
                                </div>

                                <div className={'col-span-2'}>
                                  <label htmlFor="notes"
                                         className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Notes (optional)"}/>
                                  </label>
                                  <div className="mt-1">
                                    <Input
                                        id="notes"
                                        name="notes"
                                        type="text"
                                        className={"form-field-input"}
                                        value={transactionInfo.notes}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />
                                  </div>
                                </div>

                              </div>

                              <br/>

                              <div>
                                <div>
                                  {view === 'createTransaction' && <button
                                      type="submit"
                                      className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      onClick={() => submitTransaction()}
                                  >
                                    <Translate text={"Create Transaction"}/>
                                  </button>}
                                  {view === 'editTransaction' && <button
                                      type="submit"
                                      className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      onClick={() => updateTransaction()}
                                  >
                                    <Translate text={"Update Transaction"}/>
                                  </button>}
                                  &nbsp; &nbsp; <button
                                    type="reset"
                                    className="rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                    onClick={() => {setView(''); setTransactionInfo({type: ''})}}
                                >
                                  <Translate text={"Cancel"}/>
                                </button>
                              </div>
                            </div>
                            <br />
                            <br />
                            </div>

                            <br />

                            <hr />

                            <br />

                          </div>
                      }
                      {paging}
                      <div className="mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-white divide-y divide-x divide-gray-200">
                          <tr className={'divide-x divide-gray-300'}>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              #
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"BOX"} />
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"Amount"} />
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"Type"} />
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
                              <Translate text={"Channel"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Status"} />
                            </th>
                            <th scope="col" className="px-3 py-4 text-left text-md font-semibold text-gray-900">
                              <Translate text={"Created"} />
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">&nbsp;</span>
                            </th>
                          </tr>
                          </thead>
                          <tbody className="divide-y divide-x divide-gray-200">
                          {transactions.map((transaction, b) => (
                              <tr key={transaction.transactionNumber} className={`divide-x divide-gray-300 ${b % 2 === 0 ? undefined : 'bg-white'}`}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {transaction.transactionNumber}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {transaction.boxNumber}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  <CurrencyText value={transaction.amount} />
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {transaction.type?.toTitleCase()}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {getTransactionChannel(transaction.channel)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">{getStatusName(transaction.status)}{' '}{transaction.running &&
                                    <span style={{color: '#32d62f'}} title={"Running Now"}>●</span>}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700"><DateText value={transaction.createdAt} /></td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <a className={'clickable'} onClick={() => getTransaction(transaction._id)}>
                                    <Translate text={"Edit"} /><span className="sr-only">, {transaction.transactionNumber}</span>
                                  </a>
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                      <br />
                      {pagination.count > 10 && <>{paging}</>}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>

      </>
  );
}