import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Delivery from "./pages/Delivery";

export default function Operations() {
    return (
      <main>
        <Routes>
            <Route path="delivery" element={<Delivery />} />
        </Routes>
      </main>
    );
  }